import HttpClient from "@/services/httpClient.js";
const apiPath = "/service/";
export default {
  get(params = null) {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath,
      params: params,
    }).then((response) => response.data);
  },
  getListByUser(search = "") {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "listByUser/" + search,
    }).then((response) => response.data);
  },
  getById(serviceId) {
    if (!serviceId) return Promise.reject({ message: "error" });
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + serviceId,
    }).then((response) => response.data);
  },
  create(serviceObj) {
    if (!serviceObj) return Promise.reject({ message: "error" });
    return HttpClient.apiRequest({
      method: "POST",
      url: apiPath,
      data: serviceObj,
    }).then((response) => response.data);
  },
  update(serviceObj) {
    if (!serviceObj) return Promise.reject({ message: "error" });
    if (!serviceObj.id) return Promise.reject({ message: "error" });
    return HttpClient.apiRequest({
      method: "PUT",
      url: apiPath + serviceObj.id,
      data: serviceObj,
    }).then((response) => response.data);
  },
  delete(serviceId) {
    if (!serviceId) return Promise.reject({ message: "error" });
    return HttpClient.apiRequest({
      method: "DELETE",
      url: apiPath + serviceId,
    }).then((response) => response.data);
  },
  getLecom() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "lecom/",
    }).then((response) => response.data);
  },
  getPermissionByService(serviceId) {
    if (!serviceId) return Promise.reject({ message: "error" });
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "permissionByService/" + serviceId,
    }).then((response) => response.data);
  },
  saveServicePermissions(serviceId, permissionsData) {
    if (!serviceId) return Promise.reject({ message: "error" });
    return HttpClient.apiRequest({
      method: "PUT",
      url: apiPath + "permission/" + serviceId,
      data: permissionsData,
    }).then((response) => response.data);
  },
};
