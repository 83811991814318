<template>
  <div class="pa-4">
    <v-row justify="space-between">
      <v-col class="text-h4" cols="12" sm="auto">{{ $vuetify.lang.t("$vuetify.user.title") }}</v-col>
      <v-col cols="12" sm="auto">
        <v-btn :to="{ name: 'Administrator', query: { at: 'USERS' } }" color="secondary" text large exact>
          <v-icon>mdi-arrow-left</v-icon>
          {{ $vuetify.lang.t("$vuetify.buttons.backToList") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" md="9">
        <v-card>
          <v-card-title class="primary--text justify-center">
            {{ $vuetify.lang.t("$vuetify.user." + ($route.params.id ? "updateUser" : "createUser")) }}
          </v-card-title>
          <v-card-text>
            <FormUser ref="formUser" @success="formSuccess" @error="$emit('control-overlay', false)" :showAdmin="true"></FormUser>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" depressed right large @click="submitForm">
              <v-icon left dark>mdi-content-save</v-icon>
              {{ $vuetify.lang.t("$vuetify.buttons.save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-overlay :value="loadingOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import userApi from "@/api/user";
import FormUser from "@/components/forms/User";
export default {
  name: "UserRegister",
  components: { FormUser },
  data: () => ({
    loadingOverlay: false,
  }),
  mounted() {
    // Check if is editing user
    if (this.$route.params.id) {
      // Show Overlay
      this.$emit("control-overlay", true);
      // Get User
      userApi
        .getById(this.$route.params.id)
        .then((response) => {
          // set User Obj
          this.$refs.formUser.setUserObj(response);
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.user.errors.getById"),
            text: error.message,
          });
        })
        .finally(() => {
          // Hide Overlay
          this.$emit("control-overlay", false);
        });
    }
  },
  methods: {
    // Submit Form function
    submitForm(e) {
      // Prevent default action
      e.preventDefault();
      // Show Overlay
      this.$emit("control-overlay", true);
      // validate form
      this.$refs.formUser.submitForm();
    },
    formSuccess() {
      // Redirect to Index
      this.$notify({
        group: "application",
        type: "success",
        title: this.$vuetify.lang.t("$vuetify.user.notifications.success." + (this.$route.params.id ? "updateUser" : "createUser") + ".title"),
        text: this.$vuetify.lang.t("$vuetify.user.notifications.success." + (this.$route.params.id ? "updateUser" : "createUser") + ".message"),
      });
      // Hide Overlay
      this.$emit("control-overlay", false);
    },
    backToList() {
      this.$router.push({ name: "Administrator" });
    },
  },
};
</script>
