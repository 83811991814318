export default {
  addItem(key, item) {
    const collection = this.get(key);
    collection.push(item);
    this.set(key, collection);
  },
  clear() {
    localStorage.clear();
  },
  delete(key) {
    localStorage.removeItem(key);
  },
  deleteItem(key, id) {
    let collection = this.get(key);
    const deleted = collection.find((item) => item.id === id);
    delete collection[collection.indexOf(deleted)];
    this.set(key, collection);
  },
  get(key) {
    const obj = localStorage.getItem(key);
    if (obj === undefined || obj === null) {
      return null;
    }
    return JSON.parse(obj);
  },
  getItem(key, id) {
    const collection = this.get(key);
    const item = collection.find((item) => item.id === id);
    return item;
  },
  set(key, obj) {
    localStorage.setItem(key, JSON.stringify(obj));
  },
  updateItem(key, id, item) {
    let collection = this.get(key);
    const updated = collection.find((item) => item.id === id);
    collection[collection.indexOf(updated)] = item;
    this.set(key, collection);
  },
};
