<template>
  <VAppLoadingOverlay v-model="showOverlay" />
</template>
<script>
export default {
  name: "OauthPkce",
  data: () => ({
    showOverlay: true,
    oauthObj: {
      code: "",
      codeVerifier: "",
      provider: "",
    },
  }),
  created() {
    this.oauthObj.code = this.$route.query.code;
    this.oauthObj.codeVerifier = localStorage.getItem("oauthpkce");
    this.oauthObj.provider = localStorage.getItem("authenticationType");

    let url = `${
      process.env.NODE_ENV == "development" ? process.env.VUE_APP_ROOT_API : location.origin + "/api/v1"
    }/authentication/oauth/authenticate?code=${this.oauthObj.code}&oauthpkce=${this.oauthObj.codeVerifier}&provider=${this.oauthObj.provider}`;

    window.location = url;
  },
};
</script>
