<template>
  <div class="v-text-field-default">
    <label :for="id">{{ label }}</label>
    <v-text-field
      v-bind:type="type"
      v-bind:id="id"
      v-bind:name="name"
      outlined
      dense
      v-bind="
        $attrs
        // https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance
      "
      @input="$emit('input', $event.value)"
      v-on="
        $listeners
        // https://vuejs.org/v2/guide/components-custom-events.html#Binding-Native-Events-to-Components
      "
    >
    </v-text-field>
  </div>
</template>
<script>
export default {
  // Disable automatic attribute inheritance, so that $attrs are
  // passed to the <input>, even if it's not the root element.
  // https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: "text",
      // Only allow types that essentially just render text boxes.
      validator(value) {
        return ["email", "number", "password", "search", "tel", "text", "url"].includes(value);
      },
    },
    label: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
  },
};
</script>
