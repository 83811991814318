<template>
  <v-layout fill-height align-center>
    <v-row>
      <v-col cols="10" md="6" lg="3" :class="'offset-1 offset-md-3 offset-lg-' + this.layoutDirection">
        <v-card class="elevation-12 login-card" light>
          <v-card-title class="primary--text justify-center">{{ projectTitle }}</v-card-title>
          <ValidationObserver ref="observerLogin">
            <v-form ref="form" @submit="handleLogin">
              <v-card-text>
                <ValidationProvider :name="$vuetify.lang.t('$vuetify.auth.cpfCnpj')" v-bind:rules="validationRules" v-slot="{ errors }">
                  <VAppTextField
                    @change="resetRules"
                    v-model="authObj.cpfCnpj"
                    name="cpfCnpj"
                    prepend-inner-icon="mdi-card-account-details"
                    :label="$vuetify.lang.t('$vuetify.auth.cpfCnpj')"
                    :error-messages="errors"
                    v-mask="authObj.cpfCnpj.length < 15 ? cpfMask : cnpjMask"
                  ></VAppTextField>

                  <div v-if="rules" class="v-text-field__details">
                    <div class="v-messages theme--light error--text" role="alert">
                      <div class="v-messages__wrapper">
                        <div class="v-messages__message">
                          <span style="padding-top: 0 12px" v-html="rules"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </ValidationProvider>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" type="submit" depressed right large>
                  {{ $vuetify.lang.t("$vuetify.buttons.validate") }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </ValidationObserver>
        </v-card>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
import Mask from "@/mixins/mask";
import authenticationApi from "@/api/authentication";
import parameterConfigurationApi from "@/api/parameterConfiguration";
import { enumContentConfiguration } from "@/enums/configurationKeys";
import parameterConfiguration from "@/api/parameterConfiguration";

export default {
  name: "Validate",
  mixins: [Mask],
  components: {},
  data: () => ({
    rules: "",
    authObj: {
      cpfCnpj: "",
    },
    projectTitle: "",
    layoutDirection: "8",
  }),
  computed: {
    validationRules: function () {
      return this.authObj.cpfCnpj.length < 15 ? "cpf" : "cnpj";
    },
  },
  async created() {
    this.getLayoutDirection();
    await parameterConfigurationApi
      .get()
      .then(async (configurationItems) => {
        // Set new colors configuration
        await Promise.all(
          configurationItems.map(async (element) => {
            if (element.code === enumContentConfiguration.VUEAPPTITLE && element.value) this.projectTitle = element.value;
          })
        );
      })
      .catch((error) => {
        this.$notify({
          group: "application",
          type: "error",
          title: this.$vuetify.lang.t("$vuetify.api.layoutConfiguration.errors.get"),
          text: error.message,
        });
      });
  },
  methods: {
    handleLogin(e) {
      // Prevent default action
      e.preventDefault();
      // Show Overlay
      this.$emit("control-overlay", true);
      // validate form
      authenticationApi
        .validateCPFCNPJ(encodeURIComponent(this.authObj.cpfCnpj))
        .then((response) => {
          if (response.data.Status == "100") {
            // redirecionar para cadastro
            localStorage.setItem("cpfCnpj", this.authObj.cpfCnpj);
            localStorage.setItem("redirect_to", "register");
            window.location.href = "/entrar/novo-usuario";
          } else if (response.data.Status == "0") {
            // redirecionar para login
            localStorage.setItem("cpfCnpj", this.authObj.cpfCnpj);
            localStorage.setItem("redirect_to", "login");
            window.location.href = "/entrar";
          }
        })
        .catch((error) => {
          if (error.Status == "110") {
            this.rules = `CPF/CNPJ não encontrado no Cartão Cidadão ITU, favor realizar o cadastro clicando <a href="${error.UrlRegister}" target="_blank">aqui</a> e depois retorne para entrar no Portal.`;
          } else {
            this.$notify({
              group: "application",
              type: "error",
              title: this.$vuetify.lang.t("$vuetify.api.authentication.errors.authenticate"),
              text: error.message,
            });
          }
        })
        .finally(() => {
          // Hide Overlay
          this.$emit("control-overlay", false);
        });
    },
    resetRules() {
      this.rules = "";
    },
    getLayoutDirection() {
      parameterConfiguration.getLayoutDirection().then((response) => {
        if (response.toUpperCase() == "LEFT") this.layoutDirection = 1;
        if (response.toUpperCase() == "CENTER") this.layoutDirection = 5;
        if (response.toUpperCase() == "RIGHT") this.layoutDirection = 8;
      });
    },
  },
};
</script>
