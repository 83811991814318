<!-- components/Error404.vue -->
<template>
  <div class="error-container" v-show="!isApiOk">
    <h1>Erro 404 - Página não encontrada</h1>
    <p>Desculpe, a página que você está procurando não foi encontrada.</p>
  </div>
</template>

<script>
import healthApi from "@/api/health";

export default {
  name: "Error404",
  data() {
    return {
      apiOk: true,
    };
  },
  computed: {
    isApiOk() {
      return this.apiOk;
    },
  },
  async created() {
    // Check if API is online
    await healthApi
      .get()
      .then(() => (this.apiOk = true))
      .catch(() => (this.apiOk = false));
  },
};
</script>

<style scoped>
.error-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centraliza horizontalmente */
  justify-content: center; /* Centraliza verticalmente */
  height: 100vh; /* 100% da altura da viewport para centralizar verticalmente */
  text-align: center; /* Alinha o texto no centro */
}
</style>
