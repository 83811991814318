<template>
  <v-dialog
    v-bind:id="id"
    v-bind:name="name"
    v-model="dialog"
    max-width="500"
    v-bind="
      $attrs
      // https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance
    "
    v-on="
      $listeners
      // https://vuejs.org/v2/guide/components-custom-events.html#Binding-Native-Events-to-Components
    "
  >
    <v-card>
      <v-card-title class="primary--text">{{ title }}</v-card-title>
      <v-card-text>{{ content }}</v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="close()">
          <v-icon>mdi-close</v-icon>
          {{ $vuetify.lang.t("$vuetify.buttons.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data: () => ({
    dialog: false,
  }),
  // Disable automatic attribute inheritance, so that $attrs are
  // passed to the <input>, even if it's not the root element.
  // https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show: function (newValue, old) {
      this.dialog = newValue;
      if (!newValue && old) {
        this.$emit("onclose");
      }
    },
    dialog: function (newValue, old) {
      if (!newValue && old) {
        this.$emit("onclose");
      }
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.$emit("onclose");
    },
  },
};
</script>
