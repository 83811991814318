<!-- This is the Base Button element for admin area           -->
<!-- Based on Vuetify v2                                        -->
<!-- Docs: https://vuetifyjs.com/en/components/buttons#buttons  -->
<template>
  <div class="textdivider mb-3">
    <v-row justify="center">
      <v-col cols="auto" class="textdivider__label text-uppercase">{{ label }}</v-col>
    </v-row>
    <v-divider class="textdivider__line"></v-divider>
  </div>
</template>
<script>
export default {
  name: "TextDivider",
  // Disable automatic attribute inheritance, so that $attrs are
  // passed to the <input>, even if it's not the root element.
  // https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.textdivider {
  & .textdivider__label {
    background-color: white;
    line-height: 20px;
  }
  & .textdivider__line {
    margin-top: -23px;
  }
}
</style>
