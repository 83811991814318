<template>
  <div class="d-flex justify-center mt-4">
    <label :for="id">{{ label }}</label>
    <v-radio-group
      @change="changed"
      v-model="selected"
      v-bind:id="id"
      v-bind:name="name"
      row
      mandatory
      v-bind="
        $attrs
        // https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance
      "
      v-on="
        $listeners
        // https://vuejs.org/v2/guide/components-custom-events.html#Binding-Native-Events-to-Components
      "
      outlined
      dense
      update:modelValue
    >
      <v-radio v-for="option in options" :key="option.value" :label="`${option.label}`" :value="option.value"></v-radio>
    </v-radio-group>
  </div>
</template>
<script>
export default {
  // Disable automatic attribute inheritance, so that $attrs are
  // passed to the <input>, even if it's not the root element.
  // https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    selected: null,
  }),
  methods: {
    changed(value) {
      if (typeof value !== "undefined" || !this.findInTree(this.selected)) {
        this.selected = this.valueFor(value);
        this.$emit("input", this.selected);
      }
    },
    valueFor(value) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.valueKey && value && typeof value === "object" && value.hasOwnProperty(this.valueKey)) {
        value = value[this.valueKey];
      }
      return value;
    },
  }
};
</script>
