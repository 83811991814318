import axios from "axios";
import tokenService from "@/services/token";
const HttpClient = {
  get(resource) {
    return axios.get(resource);
  },
  post(resource, data) {
    return axios.post(resource, data);
  },
  put(resource, data) {
    return axios.put(resource, data);
  },
  delete(resource) {
    return axios.delete(resource);
  },
  apiRequest(data) {
    let requestHeaders = {
      ...data.headers,
      Accept: "application/json",
    };
    if (tokenService.getToken()) {
      requestHeaders.Authorization = "Bearer " + tokenService.getToken();
    }
    return axios({
      ...data,
      headers: requestHeaders,
    });
  },
  lecomRequest(data) {
    let requestHeaders = {
      ...data.headers,
      Accept: "application/json",
    };
    return axios({
      ...data,
      headers: requestHeaders,
    });
  },
  customRequest(data) {
    return axios(data);
  },
};

export default HttpClient;
