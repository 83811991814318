<template>
  <ValidationObserver ref="observerCategory">
    <v-row class="my-0">
      <v-col cols="12" md="6">
        <ValidationProvider :name="$vuetify.lang.t('$vuetify.categories.fields.description')" rules="required" v-slot="{ errors }">
          <VAppTextField
            v-model="categoryObj.description"
            name="description"
            prepend-inner-icon="mdi-account"
            :label="$vuetify.lang.t('$vuetify.categories.fields.description')"
            :error-messages="errors"
          ></VAppTextField>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="6">
        <VAppTextField
          v-model="categoryObj.icon"
          name="icon"
          :prepend-inner-icon="'mdi-' + categoryObj.icon"
          :label="$vuetify.lang.t('$vuetify.categories.fields.icon')"
          hide-details
        ></VAppTextField>
        <span v-html="$vuetify.lang.t('$vuetify.categories.iconHelper')"></span>
      </v-col>
    </v-row>
  </ValidationObserver>
</template>

<script>
import categoryApi from "@/api/category";
export default {
  name: "FormCategory",
  data: () => ({
    // Category Obj
    categoryObj: {
      description: "",
      codFormCategory: null,
      icon: "",
    },
    selectedCodForm: null,
    listCategories: null,
  }),
  created() {
    this.loadCategories();
  },
  methods: {
    loadCategories() {
      // Get Categories
      categoryApi
        .get()
        .then((response) => {
          this.listCategories = response;
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.categories.errors.get"),
            text: error.message,
          });
        });
    },
    // Submit Function
    submitForm() {
      // Validate form
      this.$refs.observerCategory.validate().then((success) => {
        if (success) {
          // Set API Method
          let apiMethod = this.categoryObj.id ? "update" : "create";
          // Update Category
          categoryApi[apiMethod](this.categoryObj)
            .then((categoryObj) => {
              // Update Category Obj
              this.categoryObj = categoryObj;
              // Handle Model Obj
              this.handleModelObj();
              // Emit Success
              this.$emit("success", categoryObj);
            })
            .catch((error) => {
              this.$notify({
                group: "application",
                type: "error",
                title: this.$vuetify.lang.t("$vuetify.api.categories.errors." + this.categoryObj.id ? "update" : "create"),
                text: error.message,
              });
              // Emit Error
              this.$emit("error");
            });
        } else {
          // Notify Error
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.categories.notifications.errors.formObserverCategory.title"),
            text: this.$vuetify.lang.t("$vuetify.categories.notifications.errors.formObserverCategory.message"),
          });
          // Emit Error
          this.$emit("error");
        }
      });
    },
    setCategoryObj(categoryObj) {
      this.categoryObj = categoryObj;
      this.handleModelObj();
    },
    handleModelObj() {
      this.selectedCodForm = String(this.categoryObj.codFormCategory);
    },
    handleModuleBeforeSave() {
      this.categoryObj.formLecom = this.listCategories.find((element) => {
        return element.codForm === this.selectedCodForm;
      });
    },
  },
};
</script>
