import HttpClient from "@/services/httpClient.js";
const apiPath = "/parameterConfiguration/";

export default {
  get() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath,
    }).then((response) => response.data);
  },
  getByCode(code) {
    if (!code) return Promise.reject({ message: "error" });
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + code,
    }).then((response) => response.data);
  },
  save(code, value) {
    if (!code) return Promise.reject({ message: "error" });
    if (!value) return Promise.reject({ message: "error" });
    let configurationObj = {
      code: code,
      value: value,
    };
    return HttpClient.apiRequest({
      method: "PUT",
      url: apiPath + code,
      data: configurationObj,
    }).then((response) => response.data);
  },
  getLoginParameter(route) {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "loginParameter",
    }).then(async (response) => {
      localStorage.setItem("loginParameter", response.data);
      if (response.data && route != "Validate" && route != "Reset") {
        window.location.href = "/entrar/validar";
      } else if (!response.data && route == "Validate") {
        window.location.href = "/entrar";
      }
      if (!response.data) {
        localStorage.removeItem("cpfCnpj");
      }
    });
  },

  getAuthenticationType() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "authenticationType",
    }).then(async (response) => {
      localStorage.setItem("authenticationType", response.data);
    });
  },

  getOauthpkceParameter() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "oauthpkceParameter",
    }).then(async (response) => {
      localStorage.setItem("oauthpkceParameter", response.data);
    });
  },

  getOauthpkceURL() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "oauthpkceUrl",
    }).then((response) => response.data);
  },

  getOauthpkceAuthorizeURL() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "oauthpkceAuthorizeUrl",
    }).then((response) => response.data);
  },

  getOauthpkceLoginURL() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "oauthpkceLoginUrl",
    }).then((response) => response.data);
  },

  getOauthpkceLogoutURL() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "oauthpkceLogoutUrl",
    }).then((response) => response.data);
  },

  getOauthpkcePostLogoutRedirectURL() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "oauthpkcePostLogoutRedirectUrl",
    }).then((response) => response.data);
  },

  getOauthpkceClientId() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "oauthpkceClientId",
    }).then((response) => response.data);
  },

  getOauthpkceScope() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "oauthpkceScope",
    }).then((response) => response.data);
  },

  getOauthpkceRedirectUri() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "oauthpkceRedirectUri",
    }).then((response) => response.data);
  },

  getLayoutDirection() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "authLayoutDirection",
    }).then((response) => response.data);
  },

  getOauthpkceLoginButtonEnabled() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "oauthpkceLoginButtonEnabled",
    }).then((response) => response.data);
  },

  getOauthpkceLoginButtonText() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "oauthpkceLoginButtonText",
    }).then((response) => response.data);
  },

  getOauthpkceLoginAutoRedirect() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "oauthpkceLoginAutoRedirect",
    }).then((response) => response.data);
  },

  getGovBrOauthpkceURL() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "govbroauthpkceUrl",
    }).then((response) => response.data);
  },

  getGovBrOauthpkceAuthorizeURL() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "govbroauthpkceAuthorizeUrl",
    }).then((response) => response.data);
  },

  getGovBrOauthpkceLoginURL() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "govbroauthpkceLoginUrl",
    }).then((response) => response.data);
  },

  getGovBrOauthpkceLogoutURL() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "govbroauthpkceLogoutUrl",
    }).then((response) => response.data);
  },

  getGovBrOauthpkcePostLogoutRedirectURL() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "govbroauthpkcePostLogoutRedirectUrl",
    }).then((response) => response.data);
  },

  getGovBrOauthpkceClientId() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "govbroauthpkceClientId",
    }).then((response) => response.data);
  },

  getGovBrOauthpkceScope() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "govbroauthpkceScope",
    }).then((response) => response.data);
  },

  getGovBrOauthpkceRedirectUri() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "govbroauthpkceRedirectUri",
    }).then((response) => response.data);
  },

  getGovBrOauthpkceLoginButtonEnabled() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "govbroauthpkceLoginButtonEnabled",
    }).then((response) => response.data);
  },

  getGovBrOauthpkceLoginButtonText() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "govbroauthpkceLoginButtonText",
    }).then((response) => response.data);
  },

  getGovBrOauthpkceLoginAutoRedirect() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "govbroauthpkceLoginAutoRedirect",
    }).then((response) => response.data);
  },

  getGovBrServiceLevelsUrl() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "govbrServiceLevelsUrl",
    }).then((response) => response.data);
  },

  getGovBrServiceCategoriesUrl() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "govbrServiceCategoriesUrl",
    }).then((response) => response.data);
  },

  getGovBrServiceReliabilitiesUrl() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "govbrServiceReliabilitiesUrl",
    }).then((response) => response.data);
  },

  getGovBrServiceCompaniesUrl() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "govbrServiceCompaniesUrl",
    }).then((response) => response.data);
  },

  getGovBrServiceCCompanyDetailUrl() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "govbrServiceCompanyDetailUrl",
    }).then((response) => response.data);
  },

  getCompanyRegistrationEnabledForPersonUser() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "companyRegistrationEnabledForPersonUser",
    }).then((response) => response.data);
  },

  getUserType() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "userType",
    }).then((response) => response.data);
  },
};
