<template>
  <v-layout fill-height align-center>
    <v-row>
      <v-col cols="10" md="6" lg="3" :class="'offset-1 offset-md-3 offset-lg-' + this.layoutDirection">
        <v-card class="elevation-12 login-card" light>
          <v-card-title class="primary--text justify-center">{{ projectTitle }}</v-card-title>
          <ValidationObserver ref="observerLogin">
            <v-form ref="form" @submit="handleReset">
              <v-card-text>
                <div class="subtitle-2 mb-3">{{ $vuetify.lang.t("$vuetify.auth.resetSubTitle") }}</div>
                <ValidationProvider :name="$vuetify.lang.t('$vuetify.auth.password')" rules="required|min:8|password" v-slot="{ errors }">
                  <VAppTextField
                    v-model="password"
                    type="password"
                    name="password"
                    prepend-inner-icon="mdi-lock"
                    :label="$vuetify.lang.t('$vuetify.auth.password')"
                    :error-messages="errors"
                  ></VAppTextField>
                </ValidationProvider>
                <ValidationProvider
                  :name="$vuetify.lang.t('$vuetify.labels.confirmPassword')"
                  :rules="'required|equal:@' + $vuetify.lang.t('$vuetify.auth.password')"
                  v-slot="{ errors }"
                >
                  <VAppTextField
                    v-model="confirmPassword"
                    type="password"
                    name="confirmPassword"
                    prepend-inner-icon="mdi-lock"
                    v-bind:label="$vuetify.lang.t('$vuetify.labels.confirmPassword')"
                    :error-messages="errors"
                  ></VAppTextField>
                </ValidationProvider>
              </v-card-text>
              <v-card-actions>
                <v-btn :to="{ name: 'Login' }" color="primary" text large exact>{{ $vuetify.lang.t("$vuetify.auth.backToLogin") }}</v-btn>
                <v-spacer></v-spacer>
                <v-btn :loading="loadingSubmitButton" color="primary" type="submit" depressed right large>
                  {{ $vuetify.lang.t("$vuetify.buttons.send") }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </ValidationObserver>
        </v-card>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
import userApi from "@/api/user";
import parameterConfigurationApi from "@/api/parameterConfiguration";
import { enumContentConfiguration } from "@/enums/configurationKeys";
import parameterConfiguration from "@/api/parameterConfiguration";

export default {
  name: "Reset",
  metaInfo: {
    title: "Alterar senha",
  },
  data: () => ({
    password: "",
    confirmPassword: "",
    loadingSubmitButton: false,
    projectTitle: "",
    layoutDirection: "8",
  }),
  async created() {
    this.getLayoutDirection();
    await parameterConfigurationApi
      .get()
      .then(async (configurationItems) => {
        // Set new colors configuration
        await Promise.all(
          configurationItems.map(async (element) => {
            if (element.code === enumContentConfiguration.VUEAPPTITLE && element.value) this.projectTitle = element.value;
          })
        );
      })
      .catch((error) => {
        this.$notify({
          group: "application",
          type: "error",
          title: this.$vuetify.lang.t("$vuetify.api.layoutConfiguration.errors.get"),
          text: error.message,
        });
      });

    if (!this.$route.params.token) this.$router.push({ name: "Login" });
  },
  methods: {
    // Login function
    handleReset(e) {
      // Verify Token
      if (!this.$route.params.token) return;
      // Prevent default action
      e.preventDefault();
      // Show Overlay
      this.$emit("control-overlay", true);
      // validate form
      this.$refs.observerLogin.validate().then((success) => {
        if (success) {
          userApi
            .changeCredentials(this.$route.params.token, this.password)
            .then(() => {
              // Redirect to Index
              this.$router.push({ name: "Login" }, () => {
                // Hide Overlay
                this.$emit("control-overlay", false);
                // Notify Success
                this.$notify({
                  group: "application",
                  type: "success",
                  title: this.$vuetify.lang.t("$vuetify.auth.notifications.success.resetPassword.title"),
                  text: this.$vuetify.lang.t("$vuetify.auth.notifications.success.resetPassword.message"),
                });
              });
            })
            .catch((error) => {
              this.$notify({
                group: "application",
                type: "error",
                title: this.$vuetify.lang.t("$vuetify.api.authentication.errors.reset"),
                text: error.message,
              });
            })
            .finally(() => {
              // Hide Overlay
              this.$emit("control-overlay", false);
            });
        } else {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.auth.notifications.errors.formObserverReset.title"),
            text: this.$vuetify.lang.t("$vuetify.auth.notifications.errors.formObserverReset.message"),
          });
          // Hide Overlay
          this.$emit("control-overlay", false);
        }
      });
    },
    getLayoutDirection() {
      parameterConfiguration.getLayoutDirection().then((response) => {
        if (response.toUpperCase() == "LEFT") this.layoutDirection = 1;
        if (response.toUpperCase() == "CENTER") this.layoutDirection = 5;
        if (response.toUpperCase() == "RIGHT") this.layoutDirection = 8;
      });
    },
  },
};
</script>
