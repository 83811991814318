<template>
  <ValidationObserver ref="observerGroup">
    <v-row class="my-0">
      <v-col cols="12" md="12">
        <ValidationProvider :name="$vuetify.lang.t('$vuetify.groups.fields.description')" rules="required" v-slot="{ errors }">
          <VAppTextField
            v-model="groupObj.description"
            name="description"
            prepend-inner-icon="mdi-account"
            :label="$vuetify.lang.t('$vuetify.groups.fields.description')"
            :error-messages="errors"
          ></VAppTextField>
        </ValidationProvider>
      </v-col>
    </v-row>
  </ValidationObserver>
</template>

<script>
import groupApi from "@/api/group";
export default {
  name: "FormGroup",
  data: () => ({
    // Group Obj
    groupObj: {
      description: "",
      codFormGroup: null,
      icon: "",
    },
    selectedCodForm: null,
    listGroups: null,
  }),
  created() {
    this.loadGroups();
  },
  methods: {
    loadGroups() {
      // Get Groups
      groupApi
        .get()
        .then((response) => {
          this.listGroups = response;
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.groups.errors.get"),
            text: error.message,
          });
        });
    },
    // Submit Function
    submitForm() {
      // Validate form
      this.$refs.observerGroup.validate().then((success) => {
        if (success) {
          // Set API Method
          let apiMethod = this.groupObj.id ? "update" : "create";
          // Update Group
          groupApi[apiMethod](this.groupObj)
            .then((groupObj) => {
              // Update Group Obj
              this.groupObj = groupObj;
              // Handle Model Obj
              this.handleModelObj();
              // Emit Success
              this.$emit("success", groupObj);
            })
            .catch((error) => {
              this.$notify({
                group: "application",
                type: "error",
                title: this.$vuetify.lang.t("$vuetify.api.groups.errors." + this.groupObj.id ? "update" : "create"),
                text: error.message,
              });
              // Emit Error
              this.$emit("error");
            });
        } else {
          // Notify Error
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.groups.notifications.errors.formObserverGroup.title"),
            text: this.$vuetify.lang.t("$vuetify.groups.notifications.errors.formObserverGroup.message"),
          });
          // Emit Error
          this.$emit("error");
        }
      });
    },
    setGroupObj(groupObj) {
      this.groupObj = groupObj;
      this.handleModelObj();
    },
    handleModelObj() {
      this.selectedCodForm = String(this.groupObj.codFormGroup);
    },
    handleModuleBeforeSave() {
      this.groupObj.formLecom = this.listGroups.find((element) => {
        return element.codForm === this.selectedCodForm;
      });
    },
  },
};
</script>
