<!-- This is the Base Button element for admin area           -->
<!-- Based on Vuetify v2                                        -->
<!-- Docs: https://vuetifyjs.com/en/components/buttons#buttons  -->
<template>
  <v-overlay :value="value">
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
</template>
<script>
export default {
  name: "VAppLoadingOverlay",
  // Disable automatic attribute inheritance, so that $attrs are
  // passed to the <input>, even if it's not the root element.
  // https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance
  inheritAttrs: false,
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
