// Validation Messages
import { localize } from "vee-validate";
import pt from "@/languages/pt.json";
localize("pt_BR", pt);
// Validation Rules
import { required, email, min } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
extend("required", {
  ...required,
});
extend("email", {
  ...email,
});
extend("min", {
  ...min,
});
extend("equal", {
  params: ["target"],
  // Target here is the value of the target field
  validate(value, { target }) {
    return value === target;
  },
});
extend("password", {
  params: [],
  // Target here is the value of the target field
  validate(value) {
    // eslint-disable-next-line
    return /(?=(.*[0-9]))(?=.*[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-zA-Z])(?=(.*))/.test(value);
  },
});
extend("cpf", {
  params: [],
  // Target here is the value of the target field
  validate(value) {
    // eslint-disable-next-line
    return /^(\d{3}\.?\d{3}\.?\d{3}\-?\d{2})$/.test(value);
  },
});
extend("cnpj", {
  params: [],
  // Target here is the value of the target field
  validate(value) {
    // eslint-disable-next-line
    return /^(\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2})$/.test(value);
  },
});
extend("checkbox", {
  params: [],
  // Target here is the value of the target field
  validate(value) {
    // eslint-disable-next-line
    return value;
  },
});
