<template>
  <div>
    <label :for="id">{{ label }}</label>
    <v-file-input
      class="py-2"
      :accept="acceptedTypeFles.join(', ')"
      v-bind:id="id"
      v-bind:name="name"
      v-bind:rules="validationRules"
      :placeholder="$vuetify.lang.t('$vuetify.labels.clickToSelectAFile')"
      v-model="componentValue"
      outlined
      dense
      v-bind="
        $attrs
        // https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance
      "
      v-on="
        $listeners
        // https://vuejs.org/v2/guide/components-custom-events.html#Binding-Native-Events-to-Components
      "
    >
    </v-file-input>
    <small v-if="helper" v-html="helper"></small>
  </div>
</template>
<script>
export default {
  /* eslint-disable */
  // Disable automatic attribute inheritance, so that $attrs are
  // passed to the <input>, even if it's not the root element.
  // https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    helper: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    acceptedTypeFles: {
      type: Array,
      default: () => { return ["image/png", "image/jpeg"] },
    },
  },
  computed: {
    componentValue: {
      get: function () {
        return this.value
      },
      set: function (value) {
        if (!value) {
          this.$emit("input", null);
          return;
        }
        // Validate File Input Before confinue
        if (this.acceptedTypeFles.indexOf(value.type) < 0) {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.fileUpload.notifications.errors.fileExtensionError.title"),
            text: this.$vuetify.lang.t("$vuetify.fileUpload.notifications.errors.fileExtensionError.message", value.name),
          });
          this.$emit("input", null);
          return;
        }
        if (value.size > process.env.VUE_APP_FILE_SIZE_LIMIT) {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.fileUpload.notifications.errors.fileSizeError.title"),
            text: this.$vuetify.lang.t("$vuetify.fileUpload.notifications.errors.fileSizeError.message", e.name, (process.env.VUE_APP_FILE_SIZE_LIMIT / 1048576)),
          });
          this.$emit("input", null);
          return;
        }
        this.$emit('input', value)
      }
    },
    validationRules: function () {
      return this.rules
        ? this.rules
        : [
          (value) => !value || this.acceptedTypeFles.indexOf(value.type) >= 0 || this.$vuetify.lang.t("$vuetify.fileUpload.fileTypeNotAllowed"),
          (value) => !value || value.size < process.env.VUE_APP_FILE_SIZE_LIMIT || this.$vuetify.lang.t("$vuetify.fileUpload.fileSizeNotAllowed"),
        ];
    },
  },
};
</script>
