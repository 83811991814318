<template>
  <v-data-table
    class="stripped-datatable elevation-1"
    :headers="tableHeaders"
    :items="listItems"
    :loading="loadingTable"
    :items-per-page="15"
    :server-items-length="totalRecords"
    :options.sync="pagination"
    :footer-props="{ itemsPerPageOptions: [5, 10, 15, 25, 50] }"
    @update:options="getTableItems"
  >
    <template v-slot:item.actions="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn :to="{ name: 'RegisterGroups', params: { id: item.id } }" v-bind="attrs" v-on="on" small icon>
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>{{ $vuetify.lang.t("$vuetify.groups.editGroup") }}</span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>
<script>
import groupApi from "@/api/group";

import { groupPagination } from "@/constants/pagination.js";
import { groupTableId } from "@/constants/tableIds.js";
import localStorageHelper from "@/helpers/localStorageHelper.js";

export default {
  name: "GroupTable",
  data: () => ({
    listItems: [],
    totalRecords: null,
    loadingTable: false,
    tableHeaders: false,
    disableButton: false,

    pagination: null,
    tableId: `${groupTableId}-pref-pag`,
  }),
  watch: {
    pagination: {
      deep: true,
      handler(newVal, oldVal) {
        if (!oldVal) {
          if (!localStorageHelper.get(this.tableId)) {
            localStorageHelper.set(this.tableId, oldVal || groupPagination);
          }
        } else {
          localStorageHelper.set(this.tableId, newVal);
        }
      },
    },
  },
  computed: {},
  created() {
    // Get Table Headers
    this.tableHeaders = this.getTableHeaders();
  },
  methods: {
    getTableHeaders() {
      return [
        {
          text: this.$vuetify.lang.t("$vuetify.groups.fields.group"),
          align: "start",
          value: "description",
        },
        { text: this.$vuetify.lang.t("$vuetify.labels.actions"), value: "actions", width: 100, sortable: false },
      ];
    },
    getTableItems() {
      // Loading Table
      this.loadingTable = true;

      // Create pagination parameters
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;

      // Create pagination parameters
      let params = {};
      if (page) params.page = page - 1;
      if (itemsPerPage) params.size = itemsPerPage;
      if (sortBy && sortBy[0]) {
        params.sort = sortBy[0] + "," + (sortDesc[0] ? "desc" : "asc");
      }

      // Get Groups
      groupApi
        .getList(params)
        .then((response) => {
          this.listItems = response.data;
          this.totalRecords = response.totalRecords;
          this.setPagination();
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.groups.errors.get"),
            text: error.message,
          });
        })
        .finally(() => {
          // Loading Table
          this.loadingTable = false;
        });
    },
    setPagination() {
      if (!localStorageHelper.get(this.tableId)) {
        localStorageHelper.set(this.tableId, groupPagination);
      } else {
        this.pagination = Object.assign({}, localStorageHelper.get(this.tableId));
      }
    },
    toggleGroup(groupObj, active = 0) {
      if (!groupObj) return;
      if (!groupObj.id) return;
      // Loading Table
      this.disableButton = true;
      // Get Users
      groupApi
        .update({
          ...groupObj,
          active: active,
        })
        .then((response) => {
          let updateIndex = this.listItems.findIndex((element) => response.id === element.id);
          this.listItems[updateIndex].active = response.active;
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.groups.errors.update"),
            text: error.message,
          });
        })
        .finally(() => {
          // Loading Table
          this.disableButton = false;
        });
    },
  },
};
</script>
