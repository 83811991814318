import HttpClient from "@/services/httpClient.js";
const apiPath = "/document/";
export default {
  getByType(type) {
    if (!type) return Promise.reject({ message: "error" });
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "listByType/",
      params: {
        type: type,
      },
    }).then((response) => response.data);
  },
  getByUser(userName) {
    if (!userName) return Promise.reject({ message: "error" });
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "listByUser/",
      params: {
        userName: userName,
      },
    }).then((response) => response.data);
  },
  getDocumentDownload(fileId) {
    if (!fileId) return Promise.reject({ message: "error" });
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "download/" + fileId,
    }).then((response) => response.data);
  },
  upload(fileData, description, type) {
    if (!fileData) return Promise.reject({ message: "error" });
    if (!description) return Promise.reject({ message: "error" });
    if (!type) return Promise.reject({ message: "error" });
    let formData = new FormData();
    formData.append("file", fileData, description);
    formData.append("description", description);
    formData.append("type", type);
    return HttpClient.apiRequest({
      headers: { "Content-Type": "multipart/form-data" },
      method: "POST",
      url: apiPath + "upload/",
      data: formData,
    }).then((response) => response.data);
  },
  delete(documentId) {
    if (!documentId) return Promise.reject({ message: "error" });
    return HttpClient.apiRequest({
      method: "DELETE",
      url: apiPath + documentId,
    }).then((response) => response.data);
  },
};
