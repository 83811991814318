<template>
  <div class="pa-4 iframe-container">
    <v-row justify="space-between">
      <v-col class="text-h4" cols="12" sm="auto">{{ $vuetify.lang.t("$vuetify.process." + titleLabel) }}{{ lecomTitle }}</v-col>
      <v-col cols="12" sm="auto">
        <v-btn :to="{ name: 'Dashboard' }" color="secondary" text large exact>
          <v-icon>mdi-arrow-left</v-icon>
          {{ $vuetify.lang.t("$vuetify.buttons.backToDashboard") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-skeleton-loader v-if="!lecomFormUrl" type="text@3"></v-skeleton-loader>
    <iframe
      v-else
      name="bpm-iframe"
      id="bpm-iframe"
      class="iframe-element"
      :src="lecomFormUrl"
      frameborder="0"
      width="100%"
      height=""
      allow="encrypted-media"
      scrolling="yes"
    ></iframe>

    <VAppDialog
      :title="'Serviço indisponível'"
      :content="'Serviço não está disponível no momento. Por favor, tente mais tarde.'"
      :show="dialogUnavailable"
      @onclose="closeUnavailableDialog"
    >
    </VAppDialog>
  </div>
</template>

<script>
import lecomApi from "@/api/lecom";

export default {
  name: "ServiceRegister",
  data: () => ({
    lecomFormUrl: null,
    lecomTitle: "",
    dialogUnavailable: false,
  }),
  props: {
    titleLabel: {
      type: String,
      default: "view",
    },
    action: {
      type: String,
      default: "postOpenProcess",
    },
    activeTab: {
      type: String,
      default: null,
    },
  },
  mounted() {
    // Show Overlay
    this.$emit("control-overlay", true);
    // Check if is editing user
    if (this.$route.params.id) {
      // Get Lecom Form
      lecomApi[this.action](this.$route.params.id)
        .then((response) => {
          // original
          // this.lecomFormUrl = response.url;
          // this.lecomTitle = " - " + response.titleForm + " - " + response.processInstanceId;

          if (response && response.erro) {
            this.processErrorMessage();
          } else {
            this.checkFormURL(response, this.executeFormURL);
          }
        })
        .catch(() => {
          this.redirectDashboardError();
        })
        .finally(() => {
          // Hide Overlay
          this.$emit("control-overlay", false);
        });
    } else {
      this.processErrorMessage();
    }
    // Add Iframe Listener
    window.addEventListener("message", this.receiveMessage);
  },
  methods: {
    // eslint-disable-next-line
    /* eslint-disable */
    receiveMessage(event) {
      const eventType = event.data.event;

      switch (eventType) {
        case "PROCESS_FORM_SUBMIT_SUCCESS":
          // console.log("PROCESS_FORM_SUBMIT_SUCCESS");
          if (event.data.data[2] === "aprovar") {
            // console.log("aprovou");
          } else if (event.data.data[2] === "cancel") {
            // console.log("cancelou");
          } else if (event.data.data[2] === "rejeitar") {
            // console.log("rejeitou");
          } else if (event.data.data[2] === "finish") {
            // console.log("finalizou");
          }

          this.proccessNextAction(event);
          break;
        case "FORM_LOAD_FINISH":
          // console.log("FORM_LOAD_FINISH");
          this.callUrlEventIframe();
          break;
        default:
          this.proccessNextAction(event);
      }
    },
    proccessNextAction(event) {
      if (event && event.data) {
        const data = event.data.data;

        if (data && data[2]) {
          // console.log("data[2] > OK ");
          
          const userAction = data[2];
          // console.log("userAction > " + userAction);

          if (userAction === "cancel") {
            //// console.log("nextAction > NONE");
            this.redirectDashboard();
          }
        }

        if (data && data[3]) {
          // console.log("data[3] > OK ");
          const param3 = data[3];

          if (param3.entity && param3.entity.content && param3.entity.content.nextAction) {
            const nextAction = param3.entity.content.nextAction;
            // console.log("nextAction > " + nextAction);

            // OPEN_ACTIVITY NOT IMPLEMENTED
            if (nextAction === "SHOW_MESSAGE") {
              this.redirectDashboard();
            }
          }
        }
      }

      this.actionNotification(event)
    },
    actionNotification(event) {
      let eventType = "";
      let userAction = "";
      let nextAction = "";

      if (event && event.data) {
        eventType = event.data.event;

        const data = event.data.data;

        if (data && data[2]) {
          userAction = data[2];
        }

        if (data && data[3]) {
          const param3 = data[3];

          if (param3.entity && param3.entity.content && param3.entity.content.nextAction) {
            nextAction = param3.entity.content.nextAction;
          }
        }
      }

      let notificationType = "success"
      let notificationTitle = ""
      let notificationText = ""

      if (eventType === "PROCESS_FORM_SUBMIT_SUCCESS") {
        if (userAction === "aprovar") {
          notificationType = "success"
          notificationTitle = this.$vuetify.lang.t("$vuetify.process.notifications.accept.title")
          notificationText = this.$vuetify.lang.t("$vuetify.process.notifications.accept.message")
        } else if (userAction === "cancel") {
          notificationType = "error"
          notificationTitle = this.$vuetify.lang.t("$vuetify.process.notifications.cancel.title")
          notificationText = this.$vuetify.lang.t("$vuetify.process.notifications.cancel.message")
        } else if (userAction === "rejeitar") {
          notificationType = "error"
          notificationTitle = this.$vuetify.lang.t("$vuetify.process.notifications.reject.title")
          notificationText = this.$vuetify.lang.t("$vuetify.process.notifications.reject.message")
        } else if (userAction === "finish") {
          notificationType = "success"
          notificationTitle = this.$vuetify.lang.t("$vuetify.process.notifications.finish.title")
          notificationText = this.$vuetify.lang.t("$vuetify.process.notifications.finish.message")
        }

        // console.log("nextAction > " + nextAction);

        // continua
        if (nextAction === "OPEN_ACTIVITY") { 
          this.$notify({
            group: "application",
            type: "success",
            title: this.$vuetify.lang.t("$vuetify.process.notifications.success.title"),
            text: this.$vuetify.lang.t("$vuetify.process.notifications.success.message"),
          });
        // terminou
        } else if (nextAction === "SHOW_MESSAGE" || nextAction === "") { 
           // Notify Success
           this.$notify({
            group: "application",
            type: notificationType,
            title: notificationTitle,
            text: notificationText,
          });
        } 
      }

    },
    redirectDashboard(type, title, text) {
      setTimeout(() => {
        // Redirect to Index
        this.$router.push({ name: "Dashboard" }, () => {
          // Hide Overlay
          this.$emit("control-overlay", false);

          if (type && title && text) {
            // Notify Success
            this.$notify({
              group: "application",
              type: type,
              title: title,
              text: text,
            });
          }
        });
      }, 1);
    },
    redirectDashboardError() {
      this.redirectDashboard(
        "error",
        this.$vuetify.lang.t("$vuetify.process.notifications.error.initProcess.title"),
        this.$vuetify.lang.t("$vuetify.process.notifications.error.initProcess.message")
      );
    },
    callUrlEventIframe() {
      setTimeout(() => {
        let iframe = document.getElementById("bpm-iframe");
        if (iframe) {
          iframe.contentWindow.postMessage({ event: window.location.href }, this.lecomFormUrl);
        }
      }, 1000);
    },
    checkFormURL(responseUrl, callback) {
      fetch(responseUrl.url, { method: 'HEAD' })
        .then(response => {
            if (response.ok) {
              callback(true, responseUrl);
            } else {
              callback(false, responseUrl);
            }
        })
        .catch(() => {
          callback(false, responseUrl);
        });
    },
    executeFormURL(urlValida, responseUrl) {
      if (urlValida) {
        // document.getElementById('bpm-iframe').src = responseUrl.url;
        this.lecomFormUrl = responseUrl.url;
        this.lecomTitle = " - " + responseUrl.titleForm + " - " + responseUrl.processInstanceId;
      } else {        
        this.processErrorMessage();
      }
    },
    processErrorMessage () {
      this.dialogUnavailable = true;
    },
    closeUnavailableDialog() {
      this.dialogUnavailable = false;
      this.redirectDashboard();
    },
  },
  beforeDestroy() {
    // Destroi Iframe Listener
    window.removeEventListener("message", this.receiveMessage);
  },
};
</script>

<style scoped>
.description:after {
  white-space: break-spaces;
}
.iframe-container {
  height: 100%;
}
.iframe-element {
  width: 100%;
  height: calc(100% - 40px);
  border: 0;
}
iframe {
  width: 100%;
}
</style>
