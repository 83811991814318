import Vue from "vue";
import App from "./App.vue";
// Import Axios Plugin (https://axios-http.com/)
import "./plugins/axios";
// Import Vue Router (https://router.vuejs.org/)
import router from "./router";
// Import Vuex (https://vuex.vuejs.org/)
import store from "./store";
// Import Vuetify Plugin (https://vuetifyjs.com/en/)
import vuetify from "./plugins/vuetify";
// Import Aplication SCSS styles
import "./scss/application.scss";
// Register Base Elements
import "./components/base/register";
// Vee Validate (https://vee-validate.logaretm.com/v4/)
import "./plugins/veevalidate";
import { ValidationObserver, ValidationProvider } from "vee-validate";
// Import Vue Notification (http://vue-notification.yev.io/)
import Notifications from "vue-notification";
Vue.use(Notifications);
// Import Mask plugin (https://www.npmjs.com/package/v-mask)
import { VueMaskDirective } from "v-mask";
Vue.directive("mask", VueMaskDirective);
// Vee Validate
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
// Vue Config
Vue.config.productionTip = false;
// CryptoJS
import VueCryptojs from "vue-cryptojs";
Vue.use(VueCryptojs);
// Generate Vue
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
