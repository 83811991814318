<template>
  <div class="pa-4">
    <v-row justify="space-between">
      <v-col class="text-h4" cols="12" sm="auto">{{ $vuetify.lang.t("$vuetify.tabs.title") }}</v-col>
      <v-col cols="12" sm="auto">
        <v-btn :to="{ name: 'Configuration', query: { at: 'TABS' } }" color="secondary" text large exact>
          <v-icon>mdi-arrow-left</v-icon>
          {{ $vuetify.lang.t("$vuetify.buttons.backToList") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" md="9">
        <v-card>
          <v-card-title class="primary--text justify-center">
            {{ $vuetify.lang.t("$vuetify.tabs." + ($route.params.id ? "updateTab" : "createTab")) }}
          </v-card-title>
          <v-card-text>
            <FormTab ref="formTab" @success="formSuccess" @error="$emit('control-overlay', false)"></FormTab>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" depressed right large @click="submitForm">
              <v-icon left dark>mdi-content-save</v-icon>
              {{ $vuetify.lang.t("$vuetify.buttons.save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-overlay :value="loadingOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import tabApi from "@/api/tab";
import FormTab from "@/components/forms/Tab";
export default {
  name: "TabRegister",
  components: { FormTab },
  data: () => ({
    loadingOverlay: false,
  }),
  mounted() {
    // Check if is editing user
    if (this.$route.params.id) {
      // Show Overlay
      this.$emit("control-overlay", true);
      // Get Tab
      tabApi
        .getById(this.$route.params.id)
        .then((response) => {
          // set Category Obj
          this.$refs.formTab.setTabObj(response);
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.tabs.errors.getById"),
            text: error.message,
          });
        })
        .finally(() => {
          // Hide Overlay
          this.$emit("control-overlay", false);
        });
    }
  },
  methods: {
    // Submit Form function
    submitForm(e) {
      // Prevent default action
      e.preventDefault();
      // Show Overlay
      this.$emit("control-overlay", true);
      // validate form
      this.$refs.formTab.submitForm();
    },
    formSuccess() {
      // Redirect to Index
      this.$notify({
        group: "application",
        type: "success",
        title: this.$vuetify.lang.t("$vuetify.tabs.notifications.success." + (this.$route.params.id ? "updateTab" : "createTab") + ".title"),
        text: this.$vuetify.lang.t("$vuetify.tabs.notifications.success." + (this.$route.params.id ? "updateTab" : "createTab") + ".message"),
      });
      // Hide Overlay
      this.$emit("control-overlay", false);
    },
  },
};
</script>
