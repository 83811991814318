import jwt_decode from "jwt-decode";
const TOKEN_KEY = "089fcNt5BG9Lp7o_auth_token";
/**
 * Manage the how Access Tokens are being stored and retreived from storage.
 *
 * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instace.
 **/
const tokenService = {
  getToken() {
    return localStorage.getItem(TOKEN_KEY);
  },
  saveToken(accessToken) {
    localStorage.setItem(TOKEN_KEY, accessToken);
  },
  removeToken() {
    localStorage.removeItem(TOKEN_KEY);
  },
  getDecodedToken() {
    return jwt_decode(localStorage.getItem(TOKEN_KEY));
  },
};
export default tokenService;
