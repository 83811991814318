<template>
  <v-main>
    <VAppLoadingOverlay v-model="showOverlay" />
  </v-main>
</template>

<script>
import userApi from "@/api/user";

export default {
  name: "ValidateUser",
  data: () => ({
    showOverlay: true,
  }),
  created() {
    if (!this.$route.params.token) this.$router.push({ name: "Login" });
    userApi
      .validateToken(this.$route.params.token)
      .then(() => {
        // Redirect to Index
        this.$router.push({ name: "Login" }, () => {
          this.$notify({
            group: "application",
            type: "success",
            title: this.$vuetify.lang.t("$vuetify.user.notifications.success.validateUser.title"),
            text: this.$vuetify.lang.t("$vuetify.user.notifications.success.validateUser.message"),
          });
        });
      })
      .catch((error) => {
        this.$router.push({ name: "Login" }, () => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.user.errors.validate"),
            text: error.message,
          });
        });
      });
  },
};
</script>
