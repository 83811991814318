<template>
  <VAppLoadingOverlay v-model="showOverlay" />
</template>
<script>
import tokenService from "@/services/token";
import parameterConfiguration from "@/api/parameterConfiguration";

export default {
  name: "Logout",
  data: () => ({
    showOverlay: true,
    logoutURL: "",
    postLogoutRedirectUri: "",
  }),
  async created() {
    let oauthpkce = localStorage.getItem("oauthpkceParameter");
    let authenticationType = localStorage.getItem("authenticationType");

    if (oauthpkce == "true" && (authenticationType === "OAUTH" || authenticationType === "OAUTH_PKCE")) {
      await parameterConfiguration.getOauthpkceLogoutURL().then((response) => {
        this.logoutURL = response;
      });

      await parameterConfiguration.getOauthpkcePostLogoutRedirectURL().then((response) => {
        this.postLogoutRedirectUri = response;
      });
    }

    if (oauthpkce == "true" && authenticationType === "GOV_BR") {
      await parameterConfiguration.getGovBrOauthpkceLogoutURL().then((response) => {
        this.logoutURL = response;
      });

      await parameterConfiguration.getGovBrOauthpkcePostLogoutRedirectURL().then((response) => {
        this.postLogoutRedirectUri = response;
      });
    }

    this.handleLogoff();
  },
  methods: {
    async handleLogoff() {
      // Remove Token
      tokenService.removeToken();

      var oauthpkce = localStorage.getItem("oauthpkceParameter");
      if (oauthpkce == "true" && this.logoutURL !== "") {
        var idtoken = localStorage.getItem("id_token");
        window.location.href = `${this.logoutURL}?id_token_hint=${idtoken}&post_logout_redirect_uri=${this.postLogoutRedirectUri}`;
      } else {
        // Redirect to Login
        this.$router.push({ name: "Login" });
      }
    },
  },
};
</script>
