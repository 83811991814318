<template>
  <div class="pa-4">
    <v-row justify="space-between">
      <v-col class="text-h4" cols="12" sm="auto">{{ $vuetify.lang.t("$vuetify.administrator.titlePl") }}</v-col>
      <v-col cols="12" sm="auto">
        <v-btn :to="{ name: 'Dashboard' }" color="secondary" text large exact>
          <v-icon>mdi-arrow-left</v-icon>
          {{ $vuetify.lang.t("$vuetify.buttons.backToDashboard") }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" md="11">
        <v-card>
          <v-tabs v-model="configurationTabs" background-color="primary" fixed-tabs dark>
            <v-tab href="#USERS">{{ $vuetify.lang.t("$vuetify.administrator.usersAdministrator.title") }}</v-tab>
            <v-tab href="#GROUPS">{{ $vuetify.lang.t("$vuetify.administrator.groupsAdministrator.title") }}</v-tab>
            <v-tab href="#SERVICES">{{ $vuetify.lang.t("$vuetify.services.title") }}</v-tab>
            <v-tab href="#CATEGORIES">{{ $vuetify.lang.t("$vuetify.categories.title") }}</v-tab>
          </v-tabs>
          <v-card-text>
            <v-tabs-items v-model="configurationTabs">
              <v-tab-item value="USERS">
                <v-row class="justify-end">
                  <v-col cols="12" md="auto">

                    <v-btn :to="{ name: 'RegisterUsers' }" color="primary" depressed right large>
                      <v-icon>mdi-account-plus</v-icon>{{ $vuetify.lang.t("$vuetify.user.createUser") }}
                    </v-btn>

                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <UserDataTable></UserDataTable>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item value="GROUPS">
                <v-row class="justify-end">
                  <v-col cols="12" md="auto">
                    <v-btn :to="{ name: 'RegisterGroups' }" color="primary" depressed right large>
                      <v-icon>mdi-plus</v-icon>{{ $vuetify.lang.t("$vuetify.groups.addNewGroup") }}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <GroupDataTable></GroupDataTable>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item value="SERVICES">
                <v-row class="justify-end">
                  <v-col cols="12" md="auto">
                    <v-btn :to="{ name: 'RegisterServices' }" color="primary" depressed right large>
                      <v-icon>mdi-plus</v-icon>{{ $vuetify.lang.t("$vuetify.services.addNewService") }}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ServiceDataTable></ServiceDataTable>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item value="CATEGORIES">
                <v-row class="justify-end">
                  <v-col cols="12" md="auto">
                    <v-btn :to="{ name: 'RegisterCategories' }" color="primary" depressed right large>
                      <v-icon>mdi-plus</v-icon>{{ $vuetify.lang.t("$vuetify.categories.addNewCategory") }}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <CategoryDataTable></CategoryDataTable>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
          
        </v-card>
      </v-col>
    </v-row>


    <v-dialog v-model="approveConfirmDialog" max-width="500">
      <v-card v-if="userConfirmation.id">
        <v-card-title class="secondary white--text">{{ $vuetify.lang.t("$vuetify.user.approveUser") }}</v-card-title>
        <v-card-text class="pt-4">
          <p v-html="$vuetify.lang.t('$vuetify.user.approveDialogContent', userConfirmation.name)"></p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="approveConfirmDialog = false">
            {{ $vuetify.lang.t("$vuetify.buttons.close") }}
          </v-btn>
          <v-btn color="green darken-1" text @click="approveUser(userConfirmation.id)">
            {{ $vuetify.lang.t("$vuetify.buttons.approve") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="removeConfirmDialog" max-width="500">
      <v-card v-if="userConfirmation.id">
        <v-card-title class="secondary white--text">{{ $vuetify.lang.t("$vuetify.user.removeUser") }}</v-card-title>
        <v-card-text class="pt-4">
          <p v-html="$vuetify.lang.t('$vuetify.user.removeDialogContent', userConfirmation.name)"></p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="removeConfirmDialog = false">
            {{ $vuetify.lang.t("$vuetify.buttons.close") }}
          </v-btn>
          <v-btn color="red" text @click="removeUser(userConfirmation.id)">
            {{ $vuetify.lang.t("$vuetify.buttons.suspend") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="permissionsDialog" max-width="600" scrollable>
      <v-card v-if="userPermissions.id">
        <v-card-title class="secondary white--text">{{ $vuetify.lang.t("$vuetify.user.permissions") }}</v-card-title>
        <v-card-text class="pt-4 content-scroll">
          <v-skeleton-loader v-if="!listServices" type="text@3"></v-skeleton-loader>
          <v-list flat subheader two-line v-else>
            <v-subheader v-html="$vuetify.lang.t('$vuetify.user.setPermissionsContent', userPermissions.name)"></v-subheader>
            <v-list-item-group v-model="selectedPermissions" multiple active-class="blue lighten-5 primary--text">
              <v-card-title>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line hide-details></v-text-field>
                <v-list-item-action>
                  <v-checkbox v-model="selectAll"></v-checkbox>
                </v-list-item-action>
              </v-card-title>

              <template v-for="(serviceObj, index) in filteredListServices">
                <v-list-item :key="serviceObj.titleFormLecom" :value="serviceObj.id">
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title>{{ serviceObj.title }}</v-list-item-title>
                      <v-list-item-subtitle>{{ serviceObj.titleFormLecom }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon color="primary">
                        {{ active ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline" }}
                      </v-icon>
                    </v-list-item-action>
                  </template>
                </v-list-item>
                <v-divider v-if="index < listServices.length - 1" :key="index"></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="closePermissionDialog">
            {{ $vuetify.lang.t("$vuetify.buttons.close") }}
          </v-btn>
          <v-btn color="green darken-1" text @click="savePermissions(userPermissions.id)">
            {{ $vuetify.lang.t("$vuetify.buttons.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss" scoped>
  .v-btn .v-icon,
  .v-list-item--link .v-icon {
    margin-right: 0;
  }
</style>

<script>
import userApi from "@/api/user";
import serviceApi from "@/api/service";
import tokenService from "@/services/token";
import parameterConfigurationApi from "@/api/parameterConfiguration";
import { enumContentConfiguration } from "@/enums/configurationKeys";
import UserDataTable from "@/components/datatables/User";
import GroupDataTable from "@/components/datatables/Group";
import ServiceDataTable from "@/components/datatables/Service";
import CategoryDataTable from "@/components/datatables/Category";
export default {
  name: "UserList",
  components: { UserDataTable, GroupDataTable, ServiceDataTable, CategoryDataTable },
  data: () => ({
    configurationTabs: "USERS",
    listUsers: [],
    listServices: [],
    selectedPermissions: [],
    totalRecords: 0,
    loadingTable: false,
    approveConfirmDialog: false,
    removeConfirmDialog: false,
    permissionsDialog: false,
    userConfirmation: {},
    userPermissions: {},
    loggedUser: null,
    search: "",
    selectAll: false,
    newOpenModalService: 0,
    businessNameLabel: "",
  }),
  watch: {
    selectAll: function () {
      if (this.newOpenModalService != 0) {
        if (this.selectedPermissions.length != this.listServices.length) {
          this.selectedPermissions = [];
          this.listServices.forEach((element) => {
            this.selectedPermissions.push(element.id);
          });
        } else {
          this.selectedPermissions = [];
        }
      }
      this.newOpenModalService = 1;
    },
  },
  props: {
    activeTab: String,
  },
  computed: {
    filteredListServices() {
      return this.listServices.filter((service) => {
        return (
          service.title.toLowerCase().includes(this.search.toLowerCase()) || service.titleFormLecom.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
    tableHeaders: function () {
      return [
        {
          text: this.$vuetify.lang.t("$vuetify.user.fields.name"),
          align: "start",
          value: "name",
        },
        { text: this.$vuetify.lang.t("$vuetify.user.fields.email"), value: "email" },
        { text: this.$vuetify.lang.t("$vuetify.user.fields.cpf"), value: "cpf" },
        { text: this.$vuetify.lang.t("$vuetify.user.fields.phone"), value: "phone" },
        { text: "Nome do " + this.businessNameLabel, value: "businessName" },
        { text: "CNPJ do " + this.businessNameLabel, value: "businessCnpj" },
        { text: this.$vuetify.lang.t("$vuetify.user.fields.status"), value: "status.title" },
        { text: this.$vuetify.lang.t("$vuetify.labels.actions"), value: "actions", width: 200, sortable: false },
      ];
    },
  },
  async created() {
    this.loadServices();
    if (this.activeTab) this.configurationTabs = this.activeTab;
    // Get Logged User Data
    this.loggedUser = tokenService.getDecodedToken().user;
    // Check if user exists
    if (!this.loggedUser && !this.loggedUser.id) this.$router.push({ name: "Dashboard" });

    await parameterConfigurationApi.get().then((configurationItems) => {
      configurationItems.forEach((element) => {
        if (element.code === enumContentConfiguration.VUEAPPTITLECLIENT) this.businessNameLabel = element.value;
      });
    });
  },
  methods: {
    loadServices() {
      // Create pagination parameters
      let params = {
        size: 999999,
        sort: "category,asc",
      };
      // Get Users
      serviceApi
        .get(params)
        .then((response) => {
          this.listServices = response.data;
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.services.errors.get"),
            text: error.message,
          });
        });
    },
    loadUserTable(options = {}) {
      // Reset preview value
      this.loadingTable = true;
      // Create pagination parameters
      let params = {};
      if (options.page) params.page = options.page - 1;
      if (options.itemsPerPage) params.size = options.itemsPerPage;
      if (options.sortBy && options.sortBy[0]) params.sort = options.sortBy[0] + "," + (options.sortDesc[0] ? "desc" : "asc");
      // Get Users
      userApi
        .get(params)
        .then((response) => {
          this.listUsers = response.data;
          this.totalRecords = response.totalRecords;
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.user.errors.get"),
            text: error.message,
          });
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },
    removeUser(userId) {
      if (!userId) return;
      // Show Overlay
      this.$emit("control-overlay", true);
      // Get Users
      userApi
        .delete(userId)
        .then((data) => {
          if (data.deleted) {
            let updateIndex = this.listUsers.findIndex((element) => userId === element.id);
            this.listUsers[updateIndex].status.code = "USU_SUSPENSO";
            this.$notify({
              group: "application",
              type: "success",
              title: this.$vuetify.lang.t("$vuetify.user.notifications.success.removeUser.title"),
              text: this.$vuetify.lang.t("$vuetify.user.notifications.success.removeUser.message"),
            });
          } else {
            this.$notify({
              group: "application",
              type: "error",
              title: this.$vuetify.lang.t("$vuetify.user.notifications.errors.removeUser.title"),
              text: this.$vuetify.lang.t("$vuetify.user.notifications.errors.removeUser.message"),
            });
          }
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.user.errors.get"),
            text: error.message,
          });
        })
        .finally(() => {
          // Hide Overlay
          this.$emit("control-overlay", false);
          this.removeConfirmDialog = false;
        });
    },
    approveUser(userId) {
      if (!userId) return;
      // Show Overlay
      this.$emit("control-overlay", true);
      // Get Users
      userApi
        .approveUser(userId)
        .then(() => {
          let updateIndex = this.listUsers.findIndex((element) => userId === element.id);
          this.listUsers[updateIndex].status.code = "USU_ATIVO";
          this.$notify({
            group: "application",
            type: "success",
            title: this.$vuetify.lang.t("$vuetify.user.notifications.success.approveUser.title"),
            text: this.$vuetify.lang.t("$vuetify.user.notifications.success.approveUser.message"),
          });
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.user.errors.get"),
            text: error.message,
          });
        })
        .finally(() => {
          // Hide Overlay
          this.$emit("control-overlay", false);
          this.approveConfirmDialog = false;
        });
    },
    savePermissions(userId) {
      if (!userId) return;
      // Show Overlay
      this.$emit("control-overlay", true);
      let arrPermissions = this.listServices.filter((element) => this.selectedPermissions.indexOf(element.id) >= 0);
      // Get Users
      userApi
        .saveUserPermissions(userId, arrPermissions)
        .then(() => {
          this.$notify({
            group: "application",
            type: "success",
            title: this.$vuetify.lang.t("$vuetify.user.notifications.success.saveUserPermissions.title"),
            text: this.$vuetify.lang.t("$vuetify.user.notifications.success.saveUserPermissions.message"),
          });
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.user.errors.get"),
            text: error.message,
          });
        })
        .finally(() => {
          // Hide Overlay
          this.$emit("control-overlay", false);
          this.permissionsDialog = false;
        });
    },
    showPermissionDialog(userObj) {
      if (!userObj.id) return;
      // Show Overlay
      this.$emit("control-overlay", true);
      // Set User Obj
      this.userPermissions = userObj;
      // Erase Permissions
      this.selectedPermissions = [];
      // Get User Permissions
      userApi
        .getPermissionByUser(userObj.id)
        .then((response) => {
          this.selectedPermissions = response.map((a) => a.id);
          this.newOpenModalService = 0;
          if (this.selectedPermissions.length == this.listServices.length) {
            if (this.selectAll == true) this.newOpenModalService = 1;
            this.selectAll = true;
          } else {
            if (this.selectAll == false) this.newOpenModalService = 1;
            this.selectAll = false;
          }
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.services.errors.get"),
            text: error.message,
          });
        })
        .finally(() => {
          // Hide Overlay
          this.$emit("control-overlay", false);
          // Show permissions Dialog
          this.permissionsDialog = true;
        });
    },
    closePermissionDialog() {
      // Hide permissions Dialog
      this.permissionsDialog = false;
      // Set User Obj
      this.userPermissions = {};
      // Erase Permissions
      this.selectedPermissions = [];
    },
  },
};
</script>
