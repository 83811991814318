import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

// Language Files
import pt from "@/languages/pt.ts";

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: "#9c0030",
      },
    },
  },
  lang: {
    locales: { pt },
    current: "pt",
  },
  iconfont: "md",
});
