var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"stripped-datatable elevation-1",attrs:{"headers":_vm.tableHeaders,"items":_vm.listItems,"loading":_vm.loadingTable,"items-per-page":15,"server-items-length":_vm.totalRecords,"options":_vm.pagination,"footer-props":{ itemsPerPageOptions: [5, 10, 15, 25, 50] }},on:{"update:options":[function($event){_vm.pagination=$event},_vm.getTableItems]},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"to":{ name: 'RegisterServices', params: { id: item.id } },"small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.services.editService")))])]),(!item.admin)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"blue darken-2","small":"","icon":""},on:{"click":function($event){return _vm.showPermissionDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-multiple-check")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.user.permissions")))])]):_vm._e(),(item.active)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"red","small":"","icon":"","disabled":_vm.disableButton},on:{"click":function($event){return _vm.toggleService(item, 0)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.services.disableService")))])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"blue","small":"","icon":"","disabled":_vm.disableButton},on:{"click":function($event){return _vm.toggleService(item, 1)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-reload")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.services.enableService")))])])]}}])})],1),_c('v-dialog',{attrs:{"max-width":"600","scrollable":""},model:{value:(_vm.permissionsDialog),callback:function ($$v) {_vm.permissionsDialog=$$v},expression:"permissionsDialog"}},[(_vm.servicePermissions.id)?_c('v-card',[_c('v-card-title',{staticClass:"secondary white--text"},[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.user.permissions")))]),_c('v-card-text',{staticClass:"pt-4 content-scroll"},[(!_vm.listGroups)?_c('v-skeleton-loader',{attrs:{"type":"text@3"}}):_c('v-list',{attrs:{"flat":"","subheader":"","two-line":""}},[_c('v-subheader',{domProps:{"innerHTML":_vm._s(_vm.$vuetify.lang.t('$vuetify.services.setPermissionsContent', _vm.servicePermissions.title))}}),_c('v-list-item-group',{attrs:{"multiple":"","active-class":"blue lighten-5 primary--text"},model:{value:(_vm.selectedPermissions),callback:function ($$v) {_vm.selectedPermissions=$$v},expression:"selectedPermissions"}},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-list-item-action',[_c('v-checkbox',{model:{value:(_vm.selectAll),callback:function ($$v) {_vm.selectAll=$$v},expression:"selectAll"}})],1)],1),_vm._l((_vm.listGroups),function(groupObj,index){return [_c('v-list-item',{key:groupObj.description,attrs:{"value":groupObj.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(groupObj.title))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(groupObj.description))])],1),_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(active ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline")+" ")])],1)]}}],null,true)}),(index < _vm.listGroups.length - 1)?_c('v-divider',{key:index}):_vm._e()]})],2)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.closePermissionDialog}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.buttons.close"))+" ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.savePermissions(_vm.servicePermissions.id)}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.buttons.save"))+" ")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }