<template>
  <div>
    <v-card>
      <v-card-title>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line hide-details></v-text-field>
      </v-card-title>
      <v-data-table
        class="stripped-datatable elevation-1"
        :headers="tableHeaders"
        :items="listItems"
        :loading="loadingTable"
        :items-per-page="15"
        :server-items-length="totalRecords"
        :options.sync="pagination"
        :footer-props="{ itemsPerPageOptions: [5, 10, 15, 25, 50] }"
        @update:options="getTableItems"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn :to="{ name: 'RegisterServices', params: { id: item.id } }" v-bind="attrs" v-on="on" small icon>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>{{ $vuetify.lang.t("$vuetify.services.editService") }}</span>
          </v-tooltip>
          <v-tooltip bottom v-if="!item.admin">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ml-2" color="blue darken-2" @click="showPermissionDialog(item)" small icon v-bind="attrs" v-on="on">
                <v-icon>mdi-account-multiple-check</v-icon>
              </v-btn>
            </template>
            <span>{{ $vuetify.lang.t("$vuetify.user.permissions") }}</span>
          </v-tooltip>
          <v-tooltip bottom v-if="item.active">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ml-2" color="red" @click="toggleService(item, 0)" small icon v-bind="attrs" v-on="on" :disabled="disableButton">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>{{ $vuetify.lang.t("$vuetify.services.disableService") }}</span>
          </v-tooltip>
          <v-tooltip bottom v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ml-2" color="blue" @click="toggleService(item, 1)" small icon v-bind="attrs" v-on="on" :disabled="disableButton">
                <v-icon>mdi-reload</v-icon>
              </v-btn>
            </template>
            <span>{{ $vuetify.lang.t("$vuetify.services.enableService") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="permissionsDialog" max-width="600" scrollable>
      <v-card v-if="servicePermissions.id">
        <v-card-title class="secondary white--text">{{ $vuetify.lang.t("$vuetify.user.permissions") }}</v-card-title>
        <v-card-text class="pt-4 content-scroll">
          <v-skeleton-loader v-if="!listGroups" type="text@3"></v-skeleton-loader>
          <v-list flat subheader two-line v-else>
            <v-subheader v-html="$vuetify.lang.t('$vuetify.services.setPermissionsContent', servicePermissions.title)"></v-subheader>
            <v-list-item-group v-model="selectedPermissions" multiple active-class="blue lighten-5 primary--text">
              <v-card-title>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line hide-details></v-text-field>
                <v-list-item-action>
                  <v-checkbox v-model="selectAll"></v-checkbox>
                </v-list-item-action>
              </v-card-title>

              <template v-for="(groupObj, index) in listGroups">
                <v-list-item :key="groupObj.description" :value="groupObj.id">
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title>{{ groupObj.title }}</v-list-item-title>
                      <v-list-item-subtitle>{{ groupObj.description }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon color="primary">
                        {{ active ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline" }}
                      </v-icon>
                    </v-list-item-action>
                  </template>
                </v-list-item>
                <v-divider v-if="index < listGroups.length - 1" :key="index"></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="closePermissionDialog">
            {{ $vuetify.lang.t("$vuetify.buttons.close") }}
          </v-btn>
          <v-btn color="green darken-1" text @click="savePermissions(servicePermissions.id)">
            {{ $vuetify.lang.t("$vuetify.buttons.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import serviceApi from "@/api/service";
import groupApi from "@/api/group";

import { servicePagination } from "@/constants/pagination.js";
import { serviceTableId } from "@/constants/tableIds.js";
import localStorageHelper from "@/helpers/localStorageHelper.js";

export default {
  name: "ServiceTable",
  data: () => ({
    listItems: [],
    totalRecords: null,
    loadingTable: false,
    tableHeaders: false,
    disableButton: false,
    search: "",
    permissionsDialog: false,
    selectedPermissions: [],
    listGroups: [],
    servicePermissions: {},
    selectAll: false,
    newOpenModalService: 0,

    pagination: null,
    tableId: `${serviceTableId}-pref-pag`,
    searchId: `${serviceTableId}-pref-pag-search`,
  }),
  watch: {
    search: {
      deep: true,
      handler(newVal, oldVal) {
        if (!oldVal) {
          if (!localStorageHelper.get(this.searchId)) {
            localStorageHelper.set(this.searchId, oldVal || "");
          }
        } else {
          localStorageHelper.set(this.searchId, newVal);
        }
        this.getTableItems();
      },
    },
    selectAll: function () {
      if (this.newOpenModalService != 0) {
        if (this.selectedPermissions.length != this.listGroups.length) {
          this.selectedPermissions = [];
          this.listGroups.forEach((element) => {
            this.selectedPermissions.push(element.id);
          });
        } else {
          this.selectedPermissions = [];
        }
      }
      this.newOpenModalService = 1;
    },
    pagination: {
      deep: true,
      handler(newVal, oldVal) {
        if (!oldVal) {
          if (!localStorageHelper.get(this.tableId)) {
            localStorageHelper.set(this.tableId, oldVal || servicePagination);
          }
        } else {
          localStorageHelper.set(this.tableId, newVal);
        }
      },
    },
  },
  computed: {},
  created() {
    this.loadGroups();
    // Get Table Headers
    this.tableHeaders = this.getTableHeaders();
  },
  methods: {
    loadGroups() {
      // Create pagination parameters
      let params = {
        size: 999999,
        sort: "group,asc",
      };
      // Get groups
      groupApi
        .get(params)
        .then((response) => {
          this.listGroups = response;
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.groups.errors.get"),
            text: error.message,
          });
        });
    },
    getTableHeaders() {
      return [
        {
          text: this.$vuetify.lang.t("$vuetify.services.fields.title"),
          align: "start",
          value: "title",
        },
        { text: this.$vuetify.lang.t("$vuetify.services.fields.category"), value: "category.description" },
        { text: this.$vuetify.lang.t("$vuetify.services.fields.model"), value: "titleFormLecom" },
        { text: this.$vuetify.lang.t("$vuetify.labels.actions"), value: "actions", width: 140, sortable: false },
      ];
    },
    async getTableItems() {
      // Loading Table
      this.loadingTable = true;

      // Create pagination parameters
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;

      // Create pagination parameters
      let params = {};
      if (page) params.page = page - 1;
      if (itemsPerPage) params.size = itemsPerPage;
      if (sortBy && sortBy[0]) {
        params.sort = sortBy[0] + "," + (sortDesc[0] ? "desc" : "asc");
      }

      if (this.search != "") {
        params.page = 0;
      }

      params.search = this.search;

      // Get Services
      serviceApi
        .get(params)
        .then((response) => {
          this.listItems = response.data;
          this.totalRecords = response.totalRecords;
          this.setPagination();
          this.setSearch();
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.services.errors.get"),
            text: error.message,
          });
        })
        .finally(() => {
          // Loading Table
          this.loadingTable = false;
        });
    },
    setPagination() {
      if (!localStorageHelper.get(this.tableId)) {
        localStorageHelper.set(this.tableId, servicePagination);
      } else {
        this.pagination = Object.assign({}, localStorageHelper.get(this.tableId));
      }
    },
    setSearch() {
      if (!localStorageHelper.get(this.searchId)) {
        localStorageHelper.set(this.searchId, "");
      } else {
        this.search = localStorageHelper.get(this.searchId);
      }
    },
    toggleService(serviceObj, active = 0) {
      if (!serviceObj) return;

      if (!serviceObj.id) return;

      // Loading Table
      this.disableButton = true;

      // Get Users
      serviceApi
        .update({
          ...serviceObj,
          active: active,
        })
        .then((response) => {
          let updateIndex = this.listItems.findIndex((element) => response.id === element.id);
          this.listItems[updateIndex].active = response.active;
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.services.errors.update"),
            text: error.message,
          });
        })
        .finally(() => {
          // Loading Table
          this.disableButton = false;
        });
    },
    showPermissionDialog(userObj) {
      if (!userObj.id) return;

      // Show Overlay
      this.$emit("control-overlay", true);
      // Set User Obj
      this.servicePermissions = userObj;
      // Erase Permissions
      this.selectedPermissions = [];

      // Get User Permissions
      serviceApi
        .getPermissionByService(userObj.id)
        .then((response) => {
          this.selectedPermissions = response.map((a) => a.id);
          this.newOpenModalService = 0;

          if (this.selectedPermissions.length == this.listGroups.length) {
            if (this.selectAll == true) this.newOpenModalService = 1;
            this.selectAll = true;
          } else {
            if (this.selectAll == false) this.newOpenModalService = 1;
            this.selectAll = false;
          }
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.services.errors.get"),
            text: error.message,
          });
        })
        .finally(() => {
          // Hide Overlay
          this.$emit("control-overlay", false);
          // Show permissions Dialog
          this.permissionsDialog = true;
        });
    },
    savePermissions(serviceId) {
      if (!serviceId) return;

      // Show Overlay
      this.$emit("control-overlay", true);
      let arrPermissions = this.listGroups.filter((element) => this.selectedPermissions.indexOf(element.id) >= 0);

      // Get Users
      serviceApi
        .saveServicePermissions(serviceId, arrPermissions)
        .then(() => {
          this.$notify({
            group: "application",
            type: "success",
            title: this.$vuetify.lang.t("$vuetify.services.notifications.success.saveServicePermissions.title"),
            text: this.$vuetify.lang.t("$vuetify.services.notifications.success.saveServicePermissions.message"),
          });
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.user.errors.get"),
            text: error.message,
          });
        })
        .finally(() => {
          // Hide Overlay
          this.$emit("control-overlay", false);
          this.permissionsDialog = false;
        });
    },
    closePermissionDialog() {
      // Hide permissions Dialog
      this.permissionsDialog = false;
      // Set User Obj
      this.servicePermissions = {};
      // Erase Permissions
      this.selectedPermissions = [];
    },
  },
};
</script>
