import HttpClient from "@/services/httpClient.js";
const apiPath = "/tab/";
export default {
  getList(params = null) {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "list",
      params: params,
    }).then((response) => response.data);
  },
  get() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath,
    }).then((response) => response.data);
  },
  getById(tabId) {
    if (!tabId) return Promise.reject({ message: "error" });
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + tabId,
    }).then((response) => response.data);
  },
  create(tabObj) {
    if (!tabObj) return Promise.reject({ message: "error" });
    return HttpClient.apiRequest({
      method: "POST",
      url: apiPath,
      data: tabObj,
    }).then((response) => response.data);
  },
  update(tabObj) {
    if (!tabObj) return Promise.reject({ message: "error" });
    if (!tabObj.id) return Promise.reject({ message: "error" });
    return HttpClient.apiRequest({
      method: "PUT",
      url: apiPath + tabObj.id,
      data: tabObj,
    }).then((response) => response.data);
  },
  delete(tabId) {
    if (!tabId) return Promise.reject({ message: "error" });
    return HttpClient.apiRequest({
      method: "DELETE",
      url: apiPath + tabId,
    }).then((response) => response.data);
  },
};
