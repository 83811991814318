<template>
  <ValidationObserver ref="observerTab">
    <v-row class="my-0">
      <v-col cols="12" md="6">
        <ValidationProvider :name="$vuetify.lang.t('$vuetify.tabs.fields.name')" rules="required" v-slot="{ errors }">
          <VAppTextField
            v-model="tabObj.name"
            name="name"
            prepend-inner-icon="mdi-account"
            :label="$vuetify.lang.t('$vuetify.tabs.fields.name')"
            :error-messages="errors"
          ></VAppTextField>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="6">
        <ValidationProvider :name="$vuetify.lang.t('$vuetify.tabs.fields.status')" rules="required" v-slot="{ errors }">
          <VAppSelectField
            v-model="selectedStatus"
            name="value"
            :items="listStatus"
            item-text="status"
            :label="$vuetify.lang.t('$vuetify.tabs.fields.status')"
            :error-messages="errors"
            return-object
          ></VAppSelectField>
        </ValidationProvider>
      </v-col>
    </v-row>
  </ValidationObserver>
</template>

<script>
import tabApi from "@/api/tab";
export default {
  name: "FormTab",
  data: () => ({
    // Tab Obj
    tabObj: {
      id: null,
      name: null,
      status: null,
    },
    selectedStatus: null,
    // List Tabs
    listTabs: null,
    listStatus: [
      { status: "Ativo", value: true },
      { status: "Inativo", value: false },
    ],
    // listModels: null,
  }),
  created() {
    // this.loadTabs();
  },
  methods: {
    submitForm() {
      // Validate form
      this.$refs.observerTab.validate().then((success) => {
        if (success) {
          this.tabObj.status = typeof this.selectedStatus === "boolean" ? this.selectedStatus : this.selectedStatus.value;
          // Set API Method
          let apiMethod = this.tabObj.id ? "update" : "create";
          // Update Tab
          tabApi[apiMethod](this.tabObj)
            .then((tabObj) => {
              // Update Tab Obj
              this.tabObj = tabObj;
              // Emit Success
              this.$emit("success", tabObj);
            })
            .catch((error) => {
              this.$notify({
                group: "application",
                type: "error",
                title: this.$vuetify.lang.t("$vuetify.api.tabs.errors." + this.tabObj.id ? "update" : "create"),
                text: error.message,
              });
              // Emit Error
              this.$emit("error");
            });
        } else {
          // Notify Error
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.tabs.notifications.errors.formObserverTab.title"),
            text: this.$vuetify.lang.t("$vuetify.tabs.notifications.errors.formObserverTab.message"),
          });
          // Emit Error
          this.$emit("error");
        }
      });
    },
    setTabObj(tabObj) {
      this.tabObj = tabObj;
      this.handleModelObj();
    },
    handleModelObj() {
      this.selectedStatus = Boolean(this.tabObj.status);
    },
  },
};
</script>
