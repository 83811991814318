<template>
  <div>
    <label :for="id">{{ label }}</label>
    <v-color-picker
      v-bind:id="id"
      v-bind:name="name"
      mode="rgba"
      v-bind="
        $attrs
        // https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance
      "
      @input="$emit('input', $event.value)"
      v-on="
        $listeners
        // https://vuejs.org/v2/guide/components-custom-events.html#Binding-Native-Events-to-Components
      "
    ></v-color-picker>
  </div>
</template>
<script>
export default {
  // Disable automatic attribute inheritance, so that $attrs are
  // passed to the <input>, even if it's not the root element.
  // https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
  },
};
</script>
