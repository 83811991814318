import HttpClient from "@/services/httpClient.js";
const apiPath = "/health";
export default {
  get() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath,
    }).then((response) => response.data);
  },
};
