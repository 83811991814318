<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, $attrs }">
      <v-btn class="secondary py-7" width="100%" dark large v-bind="$attrs" v-on="$listeners" density="comfortable">
        <span class="snip-text" v-on="on">
          <v-icon v-if="icon">mdi-{{ icon }}</v-icon> {{ text }}
        </span>
      </v-btn>
    </template>
    <span>{{ text }}</span>
  </v-tooltip>
</template>
<style lang="scss" scoped>
.snip-text {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  white-space: nowrap;
  width: 280px;
}

.snip-text i {
  margin: 5px;
}
</style>

<script>
export default {
  // Disable automatic attribute inheritance, so that $attrs are
  // passed to the <input>, even if it's not the root element.
  // https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance
  inheritAttrs: false,
  props: {
    text: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: null,
    },
  },
};
</script>
