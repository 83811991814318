import HttpClient from "@/services/httpClient.js";
const apiPath = "/group/";
export default {
  getList(params = null) {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "list",
      params: params,
    }).then((response) => response.data);
  },
  get() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath,
    }).then((response) => response.data);
  },
  getById(groupId) {
    if (!groupId) return Promise.reject({ message: "error" });
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + groupId,
    }).then((response) => response.data);
  },
  create(groupObj) {
    if (!groupObj) return Promise.reject({ message: "error" });
    return HttpClient.apiRequest({
      method: "POST",
      url: apiPath,
      data: groupObj,
    }).then((response) => response.data);
  },
  update(groupObj) {
    if (!groupObj) return Promise.reject({ message: "error" });
    if (!groupObj.id) return Promise.reject({ message: "error" });
    return HttpClient.apiRequest({
      method: "PUT",
      url: apiPath + groupObj.id,
      data: groupObj,
    }).then((response) => response.data);
  },
  delete(groupId) {
    if (!groupId) return Promise.reject({ message: "error" });
    return HttpClient.apiRequest({
      method: "DELETE",
      url: apiPath + groupId,
    }).then((response) => response.data);
  },
  getPermissionByGroup(groupId) {
    if (!groupId) return Promise.reject({ message: "error" });
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "permissionByGroup/" + groupId,
    }).then((response) => response.data);
  },
  saveGroupPermissions(groupId, permissionsData) {
    if (!groupId) return Promise.reject({ message: "error" });
    return HttpClient.apiRequest({
      method: "PUT",
      url: apiPath + "permission/" + groupId,
      data: permissionsData,
    }).then((response) => response.data);
  },
};
