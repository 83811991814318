<template>
  <v-main>
    <VAppLoadingOverlay v-model="showOverlay" />
  </v-main>
</template>

<script>
import tokenService from "@/services/token";

export default {
  name: "ValidateUser",
  data: () => ({
    showOverlay: true,
  }),
  created() {
    if (this.$route.params.token && this.$route.params.idtoken) {
      tokenService.saveToken(this.$route.params.token);
      localStorage.setItem("id_token", this.$route.params.idtoken);
      window.location.href = location.origin;
    } else {
      window.location.href = "/entrar/login";
    }
  },
};
</script>
