const Mask = {
  data: () => ({
    cpfMask: "###.###.###-##",
    cnpjMask: "##.###.###/####-##",
    phoneMask: "(##) ####-####?#",
  }),
  methods: {
    checkMaskInput(value, mask) {
      return value.length < mask.length ? "" : value;
    },
  },
};
export default Mask;
