"use strict";

import Vue from "vue";
import axios from "axios";
//import store from '@/store'

// Full config:  https://github.com/axios/axios#request-config
axios.defaults.baseURL = process.env.NODE_ENV == "development" ? process.env.VUE_APP_ROOT_API : location.origin + "/api/v1" || "";
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json;charset=UTF-8";
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Check if is 401 to redirect to login
    /* if (tokenService.getToken() && error.request.status === 401) {
      store.dispatch('auth/logout')
    } */

    // Do something with response error
    const errorMessage = "Ocorreu um erro ao processar a solicitação. Por favor, tente novamente.";
    const noResponseError = new Error(errorMessage);
    const dataError = error.response && error.response.data ? error.response.data : null;

    return Promise.reject(dataError || noResponseError);
  }
);

const _axios = axios.create(config);

Plugin.install = function (Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
