<template>
  <v-container fluid>
    <v-tabs class="mt-4" v-model="dashboardTabs" background-color="primary" grow show-arrows center-active dark active-class="secondary">
      <v-tab href="#NEW_REQUEST" v-if="this.listTab != null && this.listTab[0].status">
        {{ this.listTab != null ? this.listTab[0].name : "" }}
      </v-tab>
      <v-tab href="#MY_PENDINGS" v-if="this.listTab != null && this.listTab[1].status">
        {{ this.listTab != null ? this.listTab[1].name : "" }}
        <v-badge v-if="countPedingProcess" color="white" :content="countPedingProcess" inline></v-badge>
      </v-tab>
      <v-tab href="#MY_PROGRESS" @click="clearTabs" v-if="this.listTab != null && this.listTab[2].status">
        {{ this.listTab != null ? this.listTab[2].name : "" }}
        <v-badge v-if="countInProcessProcess" color="white" :content="countInProcessProcess" inline></v-badge>
      </v-tab>
      <v-tab href="#CLOSED_REQUEST" @click="clearTabs" v-if="this.listTab != null && this.listTab[3].status">
        {{ this.listTab != null ? this.listTab[3].name : "" }}
        <v-badge v-if="countClosedProcess" color="white" :content="countClosedProcess" inline></v-badge>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="dashboardTabs">
      <!-- Dashboard for systems -->
      <v-tab-item value="NEW_REQUEST">
        <NewRequestContent ref="NEW_REQUEST" @scroll-to="scrollTo"></NewRequestContent>
      </v-tab-item>
      <v-tab-item value="MY_PENDINGS">
        <ProcessContent ref="MY_PENDINGS" activeTab="MY_PENDINGS" section="myPendings" icon="mdi-email-arrow-left" formTitle="answer" :listItems="listMyPendings" @scroll-to="scrollTo"></ProcessContent>
      </v-tab-item>
      <v-tab-item value="MY_PROGRESS">
        <ProcessContent
          ref="MY_PROGRESS"
          activeTab="MY_PROGRESS"
          section="inProgressRequests"
          icon="mdi-email-search"
          :listItems="listMyInProgressRequests"
          @scroll-to="scrollTo"
        ></ProcessContent>
      </v-tab-item>
      <v-tab-item value="CLOSED_REQUEST">
        <ProcessContent
          ref="CLOSED_REQUEST"
          activeTab="CLOSED_REQUEST"
          section="closeRequests"
          icon="mdi-email-search"
          :listItems="listMyCloseRequests"
          @scroll-to="scrollTo"
        ></ProcessContent>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>
<script>
import lecomApi from "@/api/lecom";
// Dashboard for systems - uncomment and register in components
// import Systems from "@/components/content/dashboard/Systems";
import NewRequestContent from "@/components/content/dashboard/NewRequestContent";
import ProcessContent from "@/components/content/dashboard/ProcessContent";
import Tab from "@/api/tab";
export default {
  name: "Dashboard",
  components: { NewRequestContent, ProcessContent },
  data: () => ({
    dashboardTabs: "DASHBOARD",
    listMyPendings: null,
    countPedingProcess: null,
    listMyInProgressRequests: null,
    countInProcessProcess: null,
    listMyCloseRequests: null,
    countClosedProcess: null,
    listNotifications: [
      {
        title: "Título 1",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse sodales mauris in magna scelerisque tincidunt. Praesent nec lectus ligula. Etiam placerat sodales dignissim. Aenean posuere, arcu eget pharetra mollis, est purus fringilla felis, non porttitor ex massa eu urna. Curabitur ut tempus nunc, non porta velit. Ut in mi posuere, sodales tortor eu, tristique risus. Nam laoreet ligula mollis odio feugiat, pharetra pretium risus consectetur. Aenean ac metus dictum, porta mi ac, ultrices nibh. Ut facilisis porta tincidunt. Nulla facilisi. Aenean malesuada leo quis turpis vehicula lobortis eget ut lorem. Aenean justo leo, faucibus sit amet sollicitudin vel, pharetra et leo. Quisque dictum mauris a libero pretium varius. Mauris euismod diam erat, in consectetur nunc efficitur vitae. Sed sed mi dolor.",
        read: false,
        date: "10/06/2021 10:00hrs",
      },
      {
        title: "Título 2",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse sodales mauris in magna scelerisque tincidunt. Praesent nec lectus ligula. Etiam placerat sodales dignissim. Aenean posuere, arcu eget pharetra mollis, est purus fringilla felis, non porttitor ex massa eu urna. Curabitur ut tempus nunc, non porta velit. Ut in mi posuere, sodales tortor eu, tristique risus. Nam laoreet ligula mollis odio feugiat, pharetra pretium risus consectetur. Aenean ac metus dictum, porta mi ac, ultrices nibh. Ut facilisis porta tincidunt. Nulla facilisi. Aenean malesuada leo quis turpis vehicula lobortis eget ut lorem. Aenean justo leo, faucibus sit amet sollicitudin vel, pharetra et leo. Quisque dictum mauris a libero pretium varius. Mauris euismod diam erat, in consectetur nunc efficitur vitae. Sed sed mi dolor.",
        read: false,
        date: "10/06/2021 10:00hrs",
      },
      {
        title: "Título 3",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse sodales mauris in magna scelerisque tincidunt. Praesent nec lectus ligula. Etiam placerat sodales dignissim. Aenean posuere, arcu eget pharetra mollis, est purus fringilla felis, non porttitor ex massa eu urna. Curabitur ut tempus nunc, non porta velit. Ut in mi posuere, sodales tortor eu, tristique risus. Nam laoreet ligula mollis odio feugiat, pharetra pretium risus consectetur. Aenean ac metus dictum, porta mi ac, ultrices nibh. Ut facilisis porta tincidunt. Nulla facilisi. Aenean malesuada leo quis turpis vehicula lobortis eget ut lorem. Aenean justo leo, faucibus sit amet sollicitudin vel, pharetra et leo. Quisque dictum mauris a libero pretium varius. Mauris euismod diam erat, in consectetur nunc efficitur vitae. Sed sed mi dolor.",
        read: true,
        date: "10/06/2021 10:00hrs",
      },
      {
        title: "Título 4",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse sodales mauris in magna scelerisque tincidunt. Praesent nec lectus ligula. Etiam placerat sodales dignissim. Aenean posuere, arcu eget pharetra mollis, est purus fringilla felis, non porttitor ex massa eu urna. Curabitur ut tempus nunc, non porta velit. Ut in mi posuere, sodales tortor eu, tristique risus. Nam laoreet ligula mollis odio feugiat, pharetra pretium risus consectetur. Aenean ac metus dictum, porta mi ac, ultrices nibh. Ut facilisis porta tincidunt. Nulla facilisi. Aenean malesuada leo quis turpis vehicula lobortis eget ut lorem. Aenean justo leo, faucibus sit amet sollicitudin vel, pharetra et leo. Quisque dictum mauris a libero pretium varius. Mauris euismod diam erat, in consectetur nunc efficitur vitae. Sed sed mi dolor.",
        read: true,
        date: "10/06/2021 10:00hrs",
      },
    ],
    selectedNotification: 0,
    listTab: null,
  }),
  props: {
    activeTab: {
      type: String,
      default: "DASHBOARD",
    },
  },
  computed: {
    myPendingHeaders: function () {
      return [
        { text: this.$vuetify.lang.t("$vuetify.process.fields.processNo"), value: "processNo" },
        { text: this.$vuetify.lang.t("$vuetify.process.fields.process"), value: "process" },
        { text: this.$vuetify.lang.t("$vuetify.process.fields.step"), value: "step" },
        { text: this.$vuetify.lang.t("$vuetify.process.fields.deadline"), value: "deadline" },
        { text: this.$vuetify.lang.t("$vuetify.process.fields.responsible"), value: "responsible" },
      ];
    },
    myInProgressRequestsHeaders: function () {
      return [
        { text: this.$vuetify.lang.t("$vuetify.process.fields.processNo"), value: "processNo" },
        { text: this.$vuetify.lang.t("$vuetify.process.fields.process"), value: "process" },
        { text: this.$vuetify.lang.t("$vuetify.process.fields.step"), value: "step" },
        { text: this.$vuetify.lang.t("$vuetify.process.fields.responsible"), value: "responsible" },
      ];
    },
    myClosedRequestsHeaders: function () {
      return [
        { text: this.$vuetify.lang.t("$vuetify.process.fields.processNo"), value: "processNo" },
        { text: this.$vuetify.lang.t("$vuetify.process.fields.process"), value: "process" },
        { text: this.$vuetify.lang.t("$vuetify.process.fields.step"), value: "step" },
        { text: this.$vuetify.lang.t("$vuetify.process.fields.responsible"), value: "responsible" },
      ];
    },
  },
  mounted() {
    this.updateInfo();
    setTimeout(() => {
      this.loadPendingProcess();
      this.loadInProcessProcess();
      this.loadClosedProcess();
    }, 2000);
  },
  created() {
    // Check and manage if the tab wanna be visible
    this.managerTabs();
    // Check active Tab
    this.dashboardTabs = this.activeTab;
    // Get Pending Process
    this.loadPendingProcess();
    // Get Progress Process
    this.loadInProcessProcess();
    // Get Closed Process
    this.loadClosedProcess();
  },
  methods: {
    updateInfo() {
      setTimeout(() => {
        if (location.pathname == "/") {
          this.loadPendingProcess();
          this.loadInProcessProcess();
          this.loadClosedProcess();
          this.updateInfo();
        }
      }, 60000);
    },
    async managerTabs() {
      this.listTab = await Tab.get();
    },
    loadPendingProcess() {
      this.listMyPendings = [];

      // Get Services
      lecomApi
        .getPending()
        .then((response) => {
          // Get List Itens
          this.listMyPendings = response;
          // Get Number of Pending Rows
          this.countPedingProcess = response.length;
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.lecom.errors.getPending"),
            text: error.message,
          });
        });
    },
    loadInProcessProcess() {
      this.listMyInProgressRequests = [];

      // Get Services
      lecomApi
        .getProgress()
        .then((response) => {
          // Get List Itens
          this.listMyInProgressRequests = response;
          // Get Number of Pending Rows
          this.countInProcessProcess = response.length;
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.lecom.errors.getProgress"),
            text: error.message,
          });
        });
    },
    loadClosedProcess() {
      this.listMyCloseRequests = [];

      // Get Services
      lecomApi
        .getDone()
        .then((response) => {
          // Get List Itens
          this.listMyCloseRequests = response;
          // Get Number of Pending Rows
          this.countClosedProcess = response.length;
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.lecom.errors.getDone"),
            text: error.message,
          });
        });
    },
    clearTabs() {
      if (this.$refs.NEW_REQUEST) this.$refs.NEW_REQUEST.clearContent();
      if (this.$refs.MY_PENDINGS) this.$refs.MY_PENDINGS.clearContent();
      if (this.$refs.MY_PROGRESS) this.$refs.MY_PROGRESS.clearContent();
      if (this.$refs.CLOSED_REQUEST) this.$refs.CLOSED_REQUEST.clearContent();
    },
    scrollTo(top) {
      this.$emit("scroll-to", top);
    },
  },
};
</script>
