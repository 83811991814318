import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";
import routes from "@/router/routes";
import tokenService from "@/services/token";
import parameterConfiguration from "@/api/parameterConfiguration";

Vue.use(VueRouter);
Vue.use(VueMeta);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// Middleware
router.beforeEach(async (to, from, next) => {
  // Check if is admin and user is logged
  const OnlyLogged = to.matched.some((record) => record.meta.OnlyLogged);
  const OnlyLoggedOut = to.matched.some((record) => record.meta.OnlyLoggedOut);
  const loggedIn = !!tokenService.getToken();

  // Does the control if it really has redirect
  if (to.name != "Login") {
    localStorage.removeItem("first_redirect_name");
    localStorage.setItem("first_redirect_name", 1);
  }
  var firstRedirectName = localStorage.getItem("first_redirect_name");
  if (to.name == "Login") {
    localStorage.setItem("first_redirect_name", ++firstRedirectName);
  }

  if (localStorage.getItem("first_redirect_name") > 2) {
    localStorage.removeItem("redirect_name");
  }

  if (OnlyLogged && !loggedIn) {
    // Save redirect route for redirect after login
    localStorage.setItem("redirect_name", to.fullPath);

    await parameterConfiguration.getLoginParameter(to.name);

    var loginParameter = localStorage.getItem("loginParameter");
    if (loginParameter != "true") {
      return next({
        name: "Login",
      });
    }
  }
  // Do not allow user to visit login page or register page if they are logged in
  if (loggedIn && OnlyLoggedOut) {
    return next({
      name: "Dashboard",
    });
  }
  // Check if token not expired
  if (loggedIn) {
    // Redirect to correct route after login
    var redirectName = localStorage.getItem("redirect_name");
    if (redirectName != null) {
      localStorage.removeItem("redirect_name");
      localStorage.removeItem("first_redirect_name");
      return next({
        path: redirectName,
      });
    }

    const loggedToken = tokenService.getDecodedToken();
    const dt = new Date();
    // Check if token was expired
    if (loggedToken.exp < dt.getTime() / 1000) {
      // Remove the token and remove Authorization header from Api Service as well
      tokenService.removeToken();
      await parameterConfiguration.getLoginParameter(to.name);
      // Redirect to Login
      return next({
        name: "Login",
      });
    }
  }

  var redirectTo = localStorage.getItem("redirect_to");
  localStorage.removeItem("redirect_to");
  if (redirectTo == "CreateUser") {
    localStorage.setItem("redirect_to", "register");
  } else if (to.name == "Forgot") {
    localStorage.setItem("redirect_to", "forgot");
    redirectTo = localStorage.getItem("redirect_to");
  }

  if (
    (to.name == "Login" && !redirectTo) ||
    (to.name == "Forgot" && !redirectTo) ||
    (to.name == "CreateUser" && !redirectTo) ||
    to.name == "Reset" ||
    to.name == "Validate"
  ) {
    parameterConfiguration.getLoginParameter(to.name);
  }

  next();
});

export default router;
