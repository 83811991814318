export const categoryPagination = {
  sortBy: ["description"],
  sortDesc: [false],
  descending: false,
  page: 1,
  itemsPerPage: 15,
  rowsPerPage: 15,
  totalItems: 0,
};

export const groupPagination = {
  sortBy: ["description"],
  sortDesc: [false],
  descending: false,
  page: 1,
  itemsPerPage: 15,
  rowsPerPage: 15,
  totalItems: 0,
};

export const servicePagination = {
  sortBy: ["title"],
  sortDesc: [false],
  descending: false,
  page: 1,
  itemsPerPage: 15,
  rowsPerPage: 15,
  totalItems: 0,
};

export const tabPagination = {
  sortBy: ["id"],
  sortDesc: [false],
  descending: false,
  page: 1,
  itemsPerPage: 15,
  rowsPerPage: 15,
  totalItems: 0,
};

export const userPagination = {
  sortBy: ["email"],
  sortDesc: [false],
  descending: false,
  page: 1,
  itemsPerPage: 15,
  rowsPerPage: 15,
  totalItems: 0,
};
