import HttpClient from "@/services/httpClient.js";
const apiPath = "/lecom/";
export default {
  getPending() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "pending/",
    }).then((response) => response.data);
  },
  getService() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "service/",
    }).then((response) => response.data);
  },
  getDone() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "done/",
    }).then((response) => response.data);
  },
  getProgress() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "progress/",
    }).then((response) => response.data);
  },
  getForm(processId) {
    if (!processId) return Promise.reject({ message: "error" });
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "viewForm/" + processId + "/",
    }).then((response) => response.data);
  },
  postOpenProcess(serviceId) {
    if (!serviceId) return Promise.reject({ message: "error" });
    return HttpClient.apiRequest({
      method: "POST",
      url: apiPath + "openProcess/" + serviceId + "/",
    }).then((response) => response.data);
  },
  getProcess(userId = 0, processId = 0) {
    let queryParam = "?";

    if (userId != 0 && userId != "") {
      queryParam += "userId=" + userId + "&";
    }

    if (processId != 0 && processId != "") {
      queryParam += "processId=" + processId + "";
    }

    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "process/" + queryParam,
    }).then((response) => response.data);
  },
};
