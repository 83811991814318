var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observerService"},[_c('v-row',{staticClass:"my-0"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$vuetify.lang.t('$vuetify.services.fields.title'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VAppTextField',{attrs:{"name":"title","prepend-inner-icon":"mdi-account","label":_vm.$vuetify.lang.t('$vuetify.services.fields.title'),"error-messages":errors},model:{value:(_vm.serviceObj.title),callback:function ($$v) {_vm.$set(_vm.serviceObj, "title", $$v)},expression:"serviceObj.title"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[(!_vm.listCategories)?_c('v-skeleton-loader',{staticClass:"pt-7",attrs:{"height":"70","type":"text"}}):_c('ValidationProvider',{attrs:{"name":_vm.$vuetify.lang.t('$vuetify.services.fields.category'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VAppSelectField',{attrs:{"name":"category","items":_vm.listCategories,"item-text":"description","label":_vm.$vuetify.lang.t('$vuetify.services.fields.category'),"error-messages":errors,"return-object":""},model:{value:(_vm.serviceObj.category),callback:function ($$v) {_vm.$set(_vm.serviceObj, "category", $$v)},expression:"serviceObj.category"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$vuetify.lang.t('$vuetify.services.fields.resume'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VAppTextArea',{attrs:{"name":"resume","label":_vm.$vuetify.lang.t('$vuetify.services.fields.resume'),"error-messages":errors},model:{value:(_vm.serviceObj.resume),callback:function ($$v) {_vm.$set(_vm.serviceObj, "resume", $$v)},expression:"serviceObj.resume"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[(!_vm.listModels)?_c('v-skeleton-loader',{attrs:{"type":"text"}}):_c('ValidationProvider',{attrs:{"name":_vm.$vuetify.lang.t('$vuetify.services.fields.model'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VAppSelectField',{attrs:{"name":"model","items":_vm.listModels,"item-value":"codForm","item-text":"nomeForm","label":_vm.$vuetify.lang.t('$vuetify.services.fields.model'),"error-messages":errors,"disabled":!!_vm.serviceObj.codFormLecom},model:{value:(_vm.selectedCodForm),callback:function ($$v) {_vm.selectedCodForm=$$v},expression:"selectedCodForm"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('VAppTextField',{attrs:{"name":"icon","prepend-inner-icon":'mdi-' + _vm.serviceObj.icon,"label":_vm.$vuetify.lang.t('$vuetify.services.fields.icon'),"hide-details":""},model:{value:(_vm.serviceObj.icon),callback:function ($$v) {_vm.$set(_vm.serviceObj, "icon", $$v)},expression:"serviceObj.icon"}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$vuetify.lang.t('$vuetify.services.iconHelper'))}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"true-value":1,"false-value":0,"label":_vm.$vuetify.lang.t('$vuetify.services.fields.active'),"hide-details":""},model:{value:(_vm.serviceObj.active),callback:function ($$v) {_vm.$set(_vm.serviceObj, "active", $$v)},expression:"serviceObj.active"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"true-value":1,"false-value":0,"label":_vm.$vuetify.lang.t('$vuetify.services.fields.permission'),"hide-details":""},model:{value:(_vm.serviceObj.permission),callback:function ($$v) {_vm.$set(_vm.serviceObj, "permission", $$v)},expression:"serviceObj.permission"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }