<template>
  <v-row>
    <v-col cols="12" sm="6" class="text-h5 mt-0">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Pesquise o nome do serviço"
        classe="expanding-search closed"
        hide-details
      ></v-text-field>
    </v-col>
    <v-col cols="12" lg="9" xl="7">
      <v-skeleton-loader v-if="!listItems" type="text@3"></v-skeleton-loader>
      <div v-else-if="listItems.length <= 0">
        <v-alert border="left" color="info" dark v-text="$vuetify.lang.t('$vuetify.services.noServicesAvailable')"></v-alert>
      </div>
      <div v-else>
        <section v-for="(item, index) in listItems" :key="index" :id="item.categoryId" :class="index > 0 ? 'mt-8' : ''">
          <div class="text-h6 mb-2" v-text="item.categoryTitle"></div>
          <v-row>
            <v-col v-for="categoryItem in item.listItems" :key="categoryItem.id" cols="12" md="4">
              <VAppDashboardButton
                :icon="categoryItem.icon"
                :text="categoryItem.title"
                @click="clickDashboardButton(categoryItem)"
              ></VAppDashboardButton>
            </v-col>
          </v-row>
        </section>
      </div>
    </v-col>
    <v-col cols="12" offset-xl="1" lg="3" xl="4">
      <!-- Anchor element to scroll -->
      <v-card ref="processCard"></v-card>
      <v-card color="blue-grey lighten-5" :class="'mb-2' + (selectedService ? '' : ' d-none')">
        <v-card-title class="primary--text" v-if="selectedService">
          <v-icon class="primary--text mr-2" v-if="selectedService.icon">mdi-{{ selectedService.icon }}</v-icon>
          {{ selectedService.title }}
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <v-card-text v-if="selectedService">
          <div class="description" v-text="selectedService.resume"></div>
        </v-card-text>
        <v-card-actions v-if="selectedService">
          <v-btn :to="{ name: 'ViewProcess', params: { id: selectedService.id }, query: { l: 'register' } }" color="secondary">
            <v-icon>mdi-text-box-plus</v-icon>
            {{ $vuetify.lang.t("$vuetify.buttons.addProcess") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<style lang="scss" scoped>
.description:after {
  white-space: break-spaces;
}
::v-deep .v-input__slot::before {
  border-color: rgba(0, 0, 0, 0.12) !important;
}
</style>

<script>
import serviceApi from "@/api/service";
export default {
  name: "NewRequestContent",
  data: () => ({
    listItems: null,
    selectedService: null,
    search: "",
  }),
  watch: {
    search: function () {
      this.filterSolicitation();
    },
  },
  computed: {},
  created() {
    // Load Services
    this.loadServices();
  },
  methods: {
    loadServices(search = "") {
      // Get Services
      serviceApi
        .getListByUser(search)
        .then((listServices) => {
          // Create auxiliar variable
          let auxIndex = null;
          // Get List Itens
          this.listItems = listServices.reduce(function (newArray, serviceObj) {
            // Check if already exist the category group
            auxIndex = newArray.findIndex((element) => element.categoryId === serviceObj.category.id);
            if (auxIndex >= 0) {
              // Just push the service inside category group
              newArray[auxIndex].listItems.push(serviceObj);
            } else {
              // Create new category Group
              newArray.push({
                categoryId: serviceObj.category.id,
                categoryTitle: serviceObj.category.description,
                listItems: [serviceObj],
              });
            }
            // Return
            return newArray;
          }, []);
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.services.errors.get"),
            text: error.message,
          });
        });
    },
    clearContent() {
      // Clear Selected
      this.selectedService = null;
    },
    clickDashboardButton(selectedItem) {
      // Select Clicked Item
      this.selectedService = selectedItem;
      // Scroll To Card
      setTimeout(() => this.$emit("scroll-to", this.$refs.processCard.$el.offsetTop), 150);
    },
    filterSolicitation() {
      this.loadServices(this.search);
    },
  },
};
</script>
